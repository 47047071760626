import Vuetify from '../../plugins/vuetify'
import * as Sentry from '@sentry/vue';
import { version } from './../../version'
import { appConfig } from '../../app-config';

export default {
  /**
   * Main Toast
   */
  showToast: (state, toast) => {
    const { color, timeout, message, buttonText, action } = toast

    state.toast = {
      message,
      color,
      timeout,
      show: true,
      buttonText,
      action,
    }
  },
  hideToast: (state) => {
    state.toast.show = false
  },
  resetToast: (state) => {
    state.toast = {
      show: false,
      color: 'black',
      message: '',
      timeout: 3000,
    }
  },

  /**
   * Theme and Layout
   */
  setGlobalTheme: (state, theme) => {
    Vuetify.framework.theme.dark = theme === 'dark'
    state.globalTheme = theme
  },
  setRTL: (state, isRTL) => {
    Vuetify.framework.rtl = isRTL
    state.isRTL = isRTL
  },
  setContentBoxed: (state, isBoxed) => {
    state.isContentBoxed = isBoxed
  },
  setMenuTheme: (state, theme) => {
    state.menuTheme = theme
  },
  setToolbarTheme: (state, theme) => {
    state.toolbarTheme = theme
  },
  setTimeZone: (state, zone) => {
    state.time.zone = zone
  },
  setTimeFormat: (state, format) => {
    state.time.format = format
  },
  setCurrency: (state, currency) => {
    state.currency = currency
  },
  setToolbarDetached: (state, isDetached) => {
    state.isToolbarDetached = isDetached
  },
  setIsAuthInitialized: (state, val) => {
    state.isAuthInitialized = val;
  },
  setUser(state, val) {
    if (state.user && state.user.stopListening) {
      state.user.stopListening();
    }
    state.user = val;

    Sentry.setUser(state.user ? {
      id: state.user.id,
      email: state.user.email,
    } : null);

    if (state.user) {
      pendo.initialize({
        visitor: {
          id: state.user.id,
          email: state.user.email,
          firstName: state.user.firstName,
          lastName: state.user.lastName,
        },
        account: {
          id: appConfig.projectId,
        },
      });
    }
  },
  setIsAuthInitialized(state, val) {
    state.isAuthInitialized = val;
  },
  setDb(state, val) {
    state.db = val;
  },
  setAuth(state, val) {
    state.auth = val;
  },
  setGlobalAppSettings(state, val) {
    state.globalAppSettings = val

    let schooldogUpdateMajor = false
    let schooldogUpdateMinor = false
    if (version && val.version) {
    
      const valVersion = val.version.split('.').map(Number)
      const currentVersion = version.split('.').map(Number)

      if (valVersion[0] > currentVersion[0]) {
        schooldogUpdateMajor = true
      } else if (valVersion[0] === currentVersion[0] && valVersion[1] > currentVersion[1]) {
        schooldogUpdateMinor = true
      } else if (valVersion[0] === currentVersion[0] && valVersion[1] === currentVersion[1] && valVersion[2] > currentVersion[2]) {
        schooldogUpdateMinor = true
      }
    }

    state.schooldogUpdateMajor = schooldogUpdateMajor
    state.schooldogUpdateMinor = schooldogUpdateMinor
  },
  setUsers(state, val) {
    const usersById = {}
    val.forEach((user) => {
      usersById[user.id] = user
    })
    state.usersById = usersById
    state.users = Object.values(usersById)
  },
  setObservationSettings(state, val) {
    state.observationSettings = val;
  },
  setOrganizationSettings(state, val) {
    state.organizationSettings = val;
  },
  setWalkSettings(state, val) {
    state.walkSettings = val;
  },
  setTasksSettings(state, val) {
    state.tasksSettings = val;
  },
  setLastTimeWalksUpdated(state, val) {
    state.lastTimeWalksUpdated = val
  },
  setWalks(state, val) {
    const walksById = {}
    val.forEach((walk) => {
      walksById[walk.id] = walk
    })
    state.walksById = walksById
    state.walks = Object.values(walksById)
  },
  removeWalk(state, val) {
    delete state.walksById[val.id]
    state.walks = Object.values(state.walksById)
  },
  setHaveWalksLoaded(state, val) {
    state.haveWalksLoaded = val;
  },
  setLastTimeTasksUpdated(state, val) {
    state.lastTimeTasksUpdated = val
  },
  setTasks(state, val) {
    const tasksById = {}
    val.forEach((task) => {
      tasksById[task.id] = task
    })
    state.tasksById = tasksById
    state.tasks = Object.values(tasksById)
  },
  setHaveTasksLoaded(state, val) {
    state.haveTasksLoaded = val;
  },
  setIsManuallyOffline(state, val) {
    state.isManuallyOffline = val;
    if (val) {
      state.isOffline = true;
    } else if (!state.isNativelyOffline) {
      state.isOffline = false;
    }
  },
  setIsNativelyOffline(state, val) {
    state.isNativelyOffline = val;
    if (val) {
      state.isOffline = true;
    } else if (!state.isManuallyOffline) {
      state.isOffline = false;
    }
  },
  setSchoolInfos(state, val) {
    state.schoolInfos = val;
  },
  setRubrics(state, val) {
    state.rubrics = val;
    state.rubricsLoaded = true;
  },
  setCycles(state, val) {
    const cyclesById = {}
    val.forEach((cycle) => {
      cyclesById[cycle.id] = cycle
    })
    state.cyclesById = cyclesById
    state.cycles = Object.values(cyclesById)
  },
  setHaveCyclesLoaded(state, val) {
    state.haveCyclesLoaded = val;
  },
  setSchoolLocations(state, val) {
    const schoolLocationsById = {}
    val.forEach((schoolLocation) => {
      schoolLocationsById[schoolLocation.id] = schoolLocation
    })
    state.schoolLocationsById = schoolLocationsById
    state.schoolLocations = Object.values(schoolLocationsById)
  },
  setInactivityTimestamp(state, val) {
    state.inactivityTimestamp = val;
  },
  setIsUnexpectedResetDialogVisible(state, val) {
    state.isUnexpectedResetDialogVisible = val;
  },
  setMessagingToken(state, val) {
    state.messagingToken = val;
  },
  setNumTimesRequestedPermission(state, val) {
    state.numTimesRequestedPermission = val;
  },
  addTaskCreatedInSession(state, val) {
    state.tasksCreatedInSession.push(val);
  },
}
