<template>
  <v-select
    v-bind="$attrs"
    :value="value"
    :items="items"
    :label="label"
    hide-details="auto"
    dense
    outlined
    required
    @input="$emit('input', $event)"
  >
    <template #selection="{ item }">
      <task-priority-chip
        v-if="item.value"
        :priority="item.value"
        style="cursor: pointer"
      />
      <div v-else>
        {{ item.text }}
      </div>
    </template>
    <template #item="{ item }">
      <v-list-item-content>
        <v-list-item-title>
          <task-priority-chip
            v-if="item.value"
            :priority="item.value"
            style="cursor: pointer"
          />
          <div v-else>
            {{ item.text }}
          </div>
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>

import { mapState } from 'vuex'
import TaskPriorityChip from './TaskPriorityChip.vue'
export default {

  components: {
    TaskPriorityChip,
  },

  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
      default: 'Priority',
    },
    showEmpty: Boolean,
    emptyLabel: {
      type: String,
      default: 'None',
    },
  },

  computed: {
    ...mapState('app', [
      'tasksSettings',
    ]),
    items () {
      const priorities = [
        'Low',
        'Medium',
        'High',
        'Critical',
      ]
      if (!this.tasksSettings.requiredFields.priority || this.showEmpty) {
        priorities.unshift(null)
      }
      return priorities.map(priority => ({
        text: priority || this.emptyLabel,
        value: priority,
      }))
    },
  },
}
</script>