<template>
  <v-expansion-panel>
    <v-expansion-panel-header>Permissions</v-expansion-panel-header>
    <v-expansion-panel-content v-if="editUser.isSchoolDogStaff">
      This user is a SchoolDog staff member and thus has all permissions.
    </v-expansion-panel-content>
    <v-expansion-panel-content v-else>
      <v-card class="mb-2">
        <v-container>
          <div class="text-subtitle-2 mb-1">
            SchoolDog Role
          </div>
          <div
            v-if="user.id !== editUser.id"
            class="text-body-1"
          >
            Users can be assigned a Role to help articulate the way they will use SchoolDog. Each role has a set of standard permissions automatically associated with it. However, the user's permissions can be further customized below.
          </div>
          <div
            v-else
            class="text-body-1"
          >
            You cannot change your own role.
          </div>
          <v-select
            v-model="editUser.role"
            :disabled="editUser.id === user.id"
            :items="roleOptions"
            :menu-props="{ offsetY: true }"
            dense
            label="Role"
            class="mt-1"
            hide-details="auto"
            outlined
            required
            :rules="[rules.required]"
          />
          <v-checkbox
            v-if="!['schooldog_site_admin', 'district_safety_leader'].includes(editUser.role)"
            v-model="editUser.isDistrictLevel"
            :disabled="editUser.id === user.id"
            dense
            class="mt-1"
            hide-details="auto"
            label="All schools (district-level user)"
          />
          <school-select
            v-if="shouldRoleSchoolIdsBeVisible"
            v-model="editUser.roleSchoolIds"
            :disabled="editUser.id === user.id"
            class="mt-1"
            label="School(s)"
            clearable
            multiple
          />
          <v-btn
            v-if="canSetDefaultPermissions && editUser.id !== user.id"
            color="primary"
            text
            class="mt-1"
            @click="setDefaultPermissions()"
          >
            Set default permissions for Role
          </v-btn>
        </v-container>
      </v-card>
      <v-card class="mb-2">
        <v-container>
          <div class="text-subtitle-2 mb-1">
            Entry/SchoolDog Walk Permissions
          </div>
          <div class="text-body-1 font-weight-light">
            All permissions for individual entries also apply to SchoolDog Walks for the specified schools.
          </div>
          <edit-permission
            v-model="editUser.permissions['log entries']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            partition-by-school
            permission-label="Log entries/start SchoolDog Walks"
          />
          <edit-permission
            v-model="editUser.permissions['view entries']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            partition-by-school
            permission-label="View other users' completed entries/SchoolDog Walks"
          />
          <edit-permission
            v-model="editUser.permissions['manage entries']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            partition-by-school
            permission-label="Manage (edit, delete, etc) other users' school-led entries/SchoolDog Walks"
          />
          <edit-permission
            v-model="editUser.permissions['manage other entries']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            permission-label="Manage (edit, delete, etc) other users' district-led and partner-led entries/SchoolDog Walks"
          />
          <edit-permission
            v-model="editUser.permissions['send neighborhood watch']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            permission-label="Send &quot;Neighborhood Watch&quot; alerts when completing SchoolDog Walks"
          />
          <edit-permission
            v-model="editUser.permissions['download entry reports']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            permission-label="Download reports for entries/SchoolDog Walks the user can access"
          />
        </v-container>
      </v-card>
      <v-card class="mb-2">
        <v-container>
          <div class="text-subtitle-2 mb-1">
            Safety Alert Permissions
          </div>
          <edit-permission
            v-model="editUser.permissions['send safety alerts']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            partition-by-school
            permission-label="Send safety alerts for entries"
          />
          <edit-permission
            v-model="editUser.permissions['receive safety alerts']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            partition-by-school
            permission-label="Receive safety alerts"
          />
          <edit-permission
            v-model="editUser.permissions['manage safety alerts']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            partition-by-school
            permission-label="View and edit all safety alerts"
          />
        </v-container>
      </v-card>
      <v-card class="mb-2">
        <v-container>
          <div class="text-subtitle-2 mb-1">
            Task Permissions
          </div>
          <edit-permission
            v-model="editUser.permissions['propose tasks']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            partition-by-school
            permission-label="Create tasks"
          />
          <edit-permission
            v-model="editUser.permissions['be proposed tasks']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            partition-by-school
            permission-label="Receive and work on tasks"
          />
          <edit-permission
            v-model="editUser.permissions['manage tasks']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            partition-by-school
            permission-label="Manage and edit tasks"
          />
        </v-container>
      </v-card>
      <v-card class="mb-2">
        <v-container>
          <div class="text-subtitle-2 mb-1">
            Drill Permissions
          </div>
          <edit-permission
            v-model="editUser.permissions['record drills']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            partition-by-school
            permission-label="View and record drills"
          />
        </v-container>
      </v-card>
      <v-card class="mb-2">
        <v-container>
          <div class="text-subtitle-2 mb-1">
            Data Analysis Permissions
          </div>
          <edit-permission
            v-model="editUser.permissions['view entry leaderboards']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            permission-label="View the entry leaderboards on the dashboard"
          />
          <edit-permission
            v-model="editUser.permissions['view data insights']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            partition-by-school
            permission-label="Access the Data Insights page"
          />
          <edit-permission
            v-model="editUser.permissions['download administrative reports']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            permission-label="Download administrative reports"
          />
        </v-container>
      </v-card>
      <v-card>
        <v-container>
          <div class="text-subtitle-2 mb-1">
            Administrative Permissions
          </div>
          <edit-permission
            v-model="editUser.permissions['manage users']"
            :disabled="editUser.id === user.id"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            permission-label="Manage and create users"
          />
          <edit-permission
            v-model="editUser.permissions['manage task users']"
            :disabled="editUser.id === user.id"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            permission-label="Manage and create users only for tasks"
          />
          <edit-permission
            v-model="editUser.permissions['manage tasks settings']"
            :disabled="editUser.id === user.id"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            permission-label="Manage tasks settings"
          />
          <edit-permission
            v-model="editUser.permissions['manage district']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            permission-label="Edit district information (name, supt, etc)"
          />
          <edit-permission
            v-model="editUser.permissions['manage schools']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            partition-by-school
            permission-label="Edit school information"
          />
          <edit-permission
            v-model="editUser.permissions['manage rubrics']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            permission-label="Edit and add rating rubrics"
          />
          <edit-permission
            v-model="editUser.permissions['manage cycles']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            permission-label="View and manage cycle goals (for all schools)"
          />
        </v-container>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from 'vuex'
import SchoolSelect from '../common/SchoolSelect.vue'
import EditPermission from './EditPermission.vue'
export default {
  name: 'EditUserPermissionsExpansionPanel',
  components: { EditPermission, SchoolSelect },
  props: {
    editUser: Object,
  },
  data() {
    return {
      isRoleInfoVisible: false,
      rules: {
        required: value => Boolean(value) || 'This field is required',
      },
    }
  },
  computed: {
    ...mapState('app', [
      'user',
    ]),
    roleOptions () {
      return [
        {
          text: 'SchoolDog Site Admin',
          value: 'schooldog_site_admin',
        },
        {
          text: 'District Safety Leader',
          value: 'district_safety_leader',
        },
        {
          text: 'School Safety Leader—Primary',
          value: 'school_safety_leader',
        },
        {
          text: 'School Safety Leader',
          value: 'school_safety_team',
        },
        {
          text: 'School Resource Officer',
          value: 'school_resource_officer',
        },
        {
          text: 'Custodian/Tech Support',
          value: 'custodian',
        },
        {
          text: 'Educator/Non-Administrator',
          value: 'teacher',
        },
        {
          text: 'Administrative Assistant/Clerical',
          value: 'administrative_assistant',
        },
        {
          text: 'Maintenance Work Order Entry',
          value: 'maintenance_work_order_manager',
        },
        {
          text: 'Task Manager',
          value: 'task_manager',
        },
      ]
    },
    shouldRoleSchoolIdsBeVisible() {
      if (this.editUser.isDistrictLevel) {
        return false
      }
      return [
        'school_resource_officer',
        'school_safety_leader',
        'school_safety_team',
        'maintenance_work_order_manager',
        'custodian',
        'administrative_assistant',
        'teacher',
        'task_manager',
      ].includes(this.editUser.role)
    },
    canSetDefaultPermissions() {
      if (!this.editUser.role) {
        return false
      }

      if ([
        'schooldog_site_admin',
        'district_safety_leader',
      ].includes(this.editUser.role) || this.editUser.isDistrictLevel) {
        return true
      }

      if ([
        'school_safety_leader',
        'school_safety_team',
        'school_resource_officer',
        'custodian',
        'administrative_assistant',
        'teacher',
        'maintenance_work_order_manager',
        'task_manager',
      ].includes(this.editUser.role) && (this.editUser.isDistrictLevel || this.editUser.roleSchoolIds?.length)) {
        return true
      }

      return false
    },
    permissions () {
      return [
        {
          value: 'log entries',
          alwaysRoles: [
            'schooldog_site_admin',
            'district_safety_leader',
          ],
          schoolRoles: [
            'school_safety_leader',
            'school_safety_team',
            'school_resource_officer',
            'teacher',
            'custodian',
            'administrative_assistant',
            'maintenance_work_order_manager',
          ],
        },
        {
          value: 'send neighborhood watch',
          alwaysRoles: [
            'schooldog_site_admin',
            'district_safety_leader',
          ],
        },
        {
          value: 'view entries',
          alwaysRoles: [
            'schooldog_site_admin',
            'district_safety_leader',
          ],
          schoolRoles: [
            'school_safety_leader',
            'school_safety_team',
            'school_resource_officer',
          ],
        },
        {
          value: 'download entry reports',
          alwaysRoles: [
            'schooldog_site_admin',
            'district_safety_leader',
            'school_safety_leader',
            'school_safety_team',
          ],
        },
        {
          value: 'manage entries',
          alwaysRoles: [
            'schooldog_site_admin',
          ],
          schoolRoles: [
            'school_safety_leader',
          ],
        },
        {
          value: 'manage other entries',
          alwaysRoles: [
            'schooldog_site_admin',
          ],
        },
        {
          value: 'view entry leaderboards',
          alwaysRoles: [
            'schooldog_site_admin',
            'district_safety_leader',
            'school_safety_leader',
            'school_safety_team',
            'school_resource_officer',
            'teacher',
            'custodian',
            'administrative_assistant',
            'maintenance_work_order_manager',
          ],
        },
        {
          value: 'view data insights',
          alwaysRoles: [
            'schooldog_site_admin',
            'district_safety_leader',
          ],
          schoolRoles: [
            'school_safety_leader',
            'school_safety_team',
            'school_resource_officer',
          ],
        },
        {
          value: 'manage cycles',
          alwaysRoles: [
            'schooldog_site_admin',
            'district_safety_leader',
          ],
        },
        {
          value: 'propose tasks',
          alwaysRoles: [
            'schooldog_site_admin',
            'district_safety_leader',
          ],
          schoolRoles: [
            'school_safety_leader',
            'school_safety_team',
            'school_resource_officer',
            'custodian',
            'administrative_assistant',
            'teacher',
            'maintenance_work_order_manager',
            'task_manager',
          ],
        },
        {
          value: 'be proposed tasks',
          alwaysRoles: [],
          schoolRoles: [
            'school_safety_leader',
            'school_safety_team',
            'school_resource_officer',
            'maintenance_work_order_manager',
            'custodian',
            'administrative_assistant',
            'task_manager',
          ],
        },
        {
          value: 'manage users',
          alwaysRoles: [
            'schooldog_site_admin',
          ],
        },
        {
          value: 'download administrative reports',
          alwaysRoles: [
            'schooldog_site_admin',
          ],
        },
        {
          value: 'manage district',
          alwaysRoles: [
            'schooldog_site_admin',
          ],
          schoolRoles: [],
        },
        {
          value: 'manage schools',
          alwaysRoles: [
            'schooldog_site_admin',
          ],
          schoolRoles: [
            'school_safety_leader',
          ],
        },
        {
          value: 'manage rubrics',
          alwaysRoles: [
            'schooldog_site_admin',
          ],
        },
        {
          value: 'record drills',
          alwaysRoles: [
            'schooldog_site_admin',
            'district_safety_leader',
          ],
          schoolRoles: [
            'school_safety_leader',
            'school_safety_team',
            'maintenance_work_order_manager',
            'school_resource_officer',
            'administrative_assistant',
          ],
        },
        {
          value: 'send safety alerts',
          alwaysRoles: [
            'schooldog_site_admin',
            'district_safety_leader',
          ],
          schoolRoles: [
            'school_safety_leader',
            'school_safety_team',
            'school_resource_officer',
            'teacher',
            'custodian',
            'administrative_assistant',
            'maintenance_work_order_manager',
          ],
        },
        {
          value: 'receive safety alerts',
          alwaysRoles: [
            'schooldog_site_admin',
            'district_safety_leader',
          ],
          schoolRoles: [
            'school_safety_leader',
            'school_safety_team',
            'school_resource_officer',
            'teacher',
            'custodian',
            'administrative_assistant',
            'maintenance_work_order_manager',
          ],
        },
        {
          value: 'manage safety alerts',
          alwaysRoles: [
            'schooldog_site_admin',
            'district_safety_leader',
          ],
          schoolRoles: [
            'school_safety_leader',
          ],
        },
      ]
    },
  },
  methods: {
    setDefaultPermissions() {
      if (!this.editUser.role) {
        return
      }

      this.permissions.forEach((permission) => {
        if (permission.alwaysRoles && permission.alwaysRoles.includes(this.editUser.role)) {
          this.$set(this.editUser.permissions, permission.value, {
            hasPermission: true,
            always: true,
            internalKey: (this.editUser.permissions[permission.value]?.internalKey || 0) + 1,
          })
        } else if (permission.schoolRoles && permission.schoolRoles.includes(this.editUser.role)) {
          this.$set(this.editUser.permissions, permission.value, {
            hasPermission: true,
            always: this.editUser.isDistrictLevel || false,
            forSchools: this.editUser.isDistrictLevel ? [] : this.editUser.roleSchoolIds,
            internalKey: (this.editUser.permissions[permission.value]?.internalKey || 0) + 1,
          })
        } else {
          this.$set(this.editUser.permissions, permission.value, {
            hasPermission: false,
            always: false,
            forSchools: [],
            internalKey: (this.editUser.permissions[permission.value]?.internalKey || 0) + 1,
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
