<template>
  <div
    v-if="entry"
    class="flex-grow-1 pa-4"
    style="width: 100%; max-width: 100%;"
  >
    <v-btn
      to="/entries"
      text
      color="primary"
      class="mb-2"
      active-class="active-route"
    >
      <v-icon>mdi-chevron-left</v-icon>
      View all entries
    </v-btn>
    <div class="text-h5 mb-2">
      Entry #{{ entryId }}
    </div> 
    <entry-card
      :observation="entry"
      no-view
      show-school
      show-walk
      class="mb-2"
      @delete="$router.replace('/entries')"
    />
  </div>
</template>

<script>
import EntryCard from '../../components/walks/EntryCard.vue';
import Observation from '../../models/Observation';

export default {
  name: 'EntryPage',
  components: { EntryCard },
  data () {
    return {
      entry: null,
    }
  },
  computed: {
    entryId () {
      return this.$route.params.entryId;
    },
  },
  watch:{
    entryId: {
      immediate: true,
      handler (entryId) {
        if (!entryId) {
          return;
        }
        this.fetchEntry(entryId);
      },
    },
  },
  beforeDestroy () {
    if (this.entry) {
      this.entry.stopListening();
    }
  },
  methods: {
    async fetchEntry (entryId) {
      this.entry = await Observation.getById(entryId);
      console.log(this.entry);
      this.entry.startListening();
    },
  },
}
</script>

<style lang="scss" scoped>
.active-route::before {
  opacity: 0;
}
</style>