<template>
  <div
    v-if="user"
    :class="{
      'pa-4': $vuetify.breakpoint.mdAndUp,
      'pa-2': !$vuetify.breakpoint.mdAndUp,
    }"
    class="d-flex flex-grow-1 flex-column"
  >
    <!-- <v-row class="flex-grow-0" dense>
      <v-col cols="12" lg="6">
          <fitness-over-time-card
            class="h-full mt-2"
            :loading="isLoading"
          ></fitness-over-time-card>
      </v-col>
    </v-row> -->
    <div
      v-if="$vuetify.breakpoint.mdAndUp"
      class="d-flex"
    >
      <user-role-disclaimer class="flex-grow-1" />
      <div v-if="haveWalksLoaded && !isOffline">
        <v-btn
          color="primary"
          text
          @click.prevent="startEditingDashboardOrder()"
        >
          <v-icon
            small
            class="mr-1"
          >
            mdi-view-dashboard-outline
          </v-icon> Customize Dashboard
        </v-btn>
      </div>
    </div>
    <div v-else>
      <user-role-disclaimer class="flex-grow-1" />
    </div>
    
    <v-row
      v-if="!haveWalksLoaded"
      class="flex-grow-0"
      dense
    >
      <v-col
        cols="12"
        md="6"
      >
        <v-skeleton-loader type="article, actions" />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-skeleton-loader type="article, actions" />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-skeleton-loader type="article, actions" />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-skeleton-loader type="article, actions" />
      </v-col>
    </v-row>

    <v-row
      v-else-if="$vuetify.breakpoint.mdAndUp"
      class="flex-grow-0"
      dense
    >
      <template v-for="(option, index) in dashboardOrder">
        <v-col
          v-if="'action-panel-card' === option.value && !option.isDisabled && $getResolvedPermission({ canAtAnySchool: 'log entries|propose tasks'})"
          :key="index"
          md="6"
        >
          <action-panel-card class="fill-height" />
        </v-col>
        <v-col
          v-if="'current-walks-card' === option.value && !option.isDisabled && $getResolvedPermission({ canAtAnySchool: 'log entries'}) && !isOffline"
          :key="index"
          md="6"
        >
          <current-walks-card class="fill-height" />
        </v-col>
        <v-col
          v-if="'observations-over-time-card' === option.value && !option.isDisabled && $canAtAnySchool('view data insights') && !isOffline"
          :key="index"
          md="6"
        >
          <observations-over-time-card
            class="fill-height"
            dashboard
          />
        </v-col>
        <v-col
          v-if="'leaderboard-card' === option.value && !option.isDisabled && $canAtAnySchool('view entry leaderboards') && !isOffline"
          :key="index"
          md="6"
        >
          <leaderboard-card class="fill-height" />
        </v-col>
        <v-col
          v-if="'top-walkers-card' === option.value && !option.isDisabled && $can('view entry leaderboards') && !isOffline"
          :key="index"
          md="6"
        >
          <top-walkers-card class="fill-height" />
        </v-col>
        <v-col
          v-if="'insight-query-card' === option.value.substring(0, 18) && user.canAtAnySchool('view data insights') && !option.isDisabled && !isOffline"
          :key="index"
          md="6"
        >
          <observations-over-time-card
            class="fill-height"
            dashboard
            :insight-name="option.value.substring(19)"
          />
        </v-col>
        <v-col
          v-if="'recent-rubrics-card' === option.value && !option.isDisabled && $getResolvedPermission({ canAtAnySchool: 'log entries|manage entries|manage other entries'}) && !isOffline"
          :key="index"
          md="6"
        >
          <rubric-results-card class="fill-height" />
        </v-col>
      </template>
    </v-row>
    <v-row
      v-else
      class="flex-grow-0"
      dense
    >
      <v-col
        v-if="canShowActionPanel"
        cols="12"
      >
        <action-panel-card />
        <v-btn
          v-if="!isViewingMobileDashboard && !isOffline"
          color="primary"
          text
          style="width:100%"
          @click="isViewingMobileDashboard = true"
        >
          View dashboard
          <v-icon
            small
            class="ml-1"
          >
            mdi-arrow-down
          </v-icon>
        </v-btn>
      </v-col>
      <template v-if="!isOffline && (isViewingMobileDashboard || !canShowActionPanel)">
        <template v-for="(option, index) in dashboardOrder">
          <v-col
            v-if="'current-walks-card' === option.value && !option.isDisabled && $getResolvedPermission({ canAtAnySchool: 'log entries'})"
            :key="index"
            cols="12"
          >
            <current-walks-card class="fill-height" />
          </v-col>
          <v-col
            v-if="'observations-over-time-card' === option.value && !option.isDisabled && $canAtAnySchool('view data insights')"
            :key="index"
            cols="12"
          >
            <observations-over-time-card
              class="fill-height"
              dashboard
            />
          </v-col>
          <v-col
            v-if="'leaderboard-card' === option.value && !option.isDisabled && $canAtAnySchool('view entry leaderboards')"
            :key="index"
            cols="12"
          >
            <leaderboard-card class="fill-height" />
          </v-col>
          <v-col
            v-if="'top-walkers-card' === option.value && !option.isDisabled && $can('view entry leaderboards')"
            :key="index"
            cols="12"
          >
            <top-walkers-card class="fill-height" />
          </v-col>
          <v-col
            v-if="'insight-query-card' === option.value.substring(0, 18) && user.canAtAnySchool('view data insights') && !option.isDisabled"
            :key="index"
            cols="12"
          >
            <observations-over-time-card
              class="fill-height"
              dashboard
              :insight-name="option.value.substring(19)"
            />
          </v-col>
          <v-col
            v-if="'recent-rubrics-card' === option.value && !option.isDisabled && $getResolvedPermission({ canAtAnySchool: 'log entries|manage entries|manage other entries'})"
            :key="index"
            cols="12"
          >
            <rubric-results-card class="fill-height" />
          </v-col>
        </template>
      </template>
    </v-row>
    <div v-if="!$vuetify.breakpoint.mdAndUp && haveWalksLoaded && isViewingMobileDashboard && !isOffline">
      <v-btn
        color="primary"
        text
        @click.prevent="startEditingDashboardOrder()"
      >
        <v-icon
          small
          class="mr-1"
        >
          mdi-view-dashboard-outline
        </v-icon> Customize Dashboard
      </v-btn>
    </div>

    <v-dialog
      v-model="isCustomizeDashboardDialogVisible"
      max-width="600px"
    >
      <v-card>
        <v-card-title>Customize Dashboard</v-card-title>
        <v-card-subtitle>Rearrange and choose which data you want to see when you open the dashboard.</v-card-subtitle>
        <v-card-text> 
          <!-- Drag and drop using vuedraggable of the names of components that corresponds to `user.dashboardPreferences` -->
          <vuedraggable
            v-model="editDashboardOrder"
            group="options"
            handle=".handle"
          >
            <div
              v-for="option in editDashboardOrder"
              :key="option.value"
              class="d-flex mb-1 items-center"
            >
              <v-icon
                class="handle"
                style="cursor: move;"
              >
                mdi-drag-vertical
              </v-icon>
              <v-checkbox
                :input-value="!option.isDisabled"
                :disabled="['current-walks-card', 'leaderboard-card', 'top-walkers-card', 'action-panel-card'].includes(option.value) && !option.isDisabled"
                :label="option.label"
                color="primary"
                hide-details="auto"
                dense
                class="pt-0 mt-0"
                @change="toggleDashboardOption(option)"
              />
            </div>
          </vuedraggable>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="isCustomizeDashboardDialogVisible = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="saveDashboardOrder()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-row class="flex-grow-0" dense>

    </v-row> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import store from './../../store'
import LeaderboardCard from '../../components/dashboard/LeaderboardCard.vue';
import UserRoleDisclaimer from '../../components/common/UserRoleDisclaimer.vue';
import CurrentWalksCard from '../../components/dashboard/CurrentWalksCard.vue';
import CheckPermissionMixin from '../../mixins/CheckPermissionMixin';
import TopWalkersCard from '../../components/dashboard/TopWalkersCard.vue';
import ObservationsOverTimeCard from '../../components/insights/ObservationsOverTimeCard.vue';
import RubricResultsCard from '../../components/dashboard/RubricResultsCard.vue';
import vuedraggable from 'vuedraggable'
import ActionPanelCard from '../../components/dashboard/ActionPanelCard.vue';

export default {
  name: 'DashboardPage',
  components: {
    LeaderboardCard,
    UserRoleDisclaimer,
    CurrentWalksCard,
    TopWalkersCard,
    ObservationsOverTimeCard,
    RubricResultsCard,
    vuedraggable,
    ActionPanelCard,
  },
  mixins: [
    CheckPermissionMixin,
  ],
  canAtAnySchool: 'log entries|manage entries|manage other entries|view entry leaderboards|view data insights',
  data() {
    return {
      isLoading: false,
      isCustomizeDashboardDialogVisible: false,
      editDashboardOrder: null,
      isViewingMobileDashboard: false,
    };
  },
  computed: {
    ...mapState('app', [
      'haveWalksLoaded',
      'isOffline',
      'organizationSettings',
      'user',
    ]),
    dashboardOrderOptions () {
      const options = []
      if (this.$getResolvedPermission({ canAtAnySchool: 'log entries|propose tasks' })) {
        options.push({
          label: 'Action Panel',
          value: 'action-panel-card',
        })
      }
      if (this.$canAtAnySchool('view data insights')) {
        options.push({
          label: 'My Logged Entries',
          value: 'observations-over-time-card',
        })
      }
      if (this.$can('view entry leaderboards')) {
        options.push({
          label: 'School Leaderboard',
          value: 'leaderboard-card',
        })
      }
      if (this.$can('view entry leaderboards')) {
        options.push({
          label: 'Top Walkers',
          value: 'top-walkers-card',
        })
      }
      if (this.$getResolvedPermission({ canAtAnySchool: 'log entries' })) {
        options.push({
          label: 'My SchoolDog Walks',
          value: 'current-walks-card',
        })
      }
      if (this.$getResolvedPermission({ canAtAnySchool: 'log entries|manage entries|manage other entries' })) {
        options.push({
          label: 'Recent Ratings',
          value: 'recent-rubrics-card',
        })
      }
      if (this.$canAtAnySchool('view data insights') && this.user.favoriteInsightQueries && this.user.favoriteInsightQueries.length) {
        this.user.favoriteInsightQueries.forEach((query) => {
          if (!this.getInsightCountFromName(query.insightName)) {
            return
          }
          options.push({
            label: `Data Insights: ${query.insightName}`,
            value: `insight-query-card-${query.insightName}`,
          })
        })
      }
      return options
    },
    dashboardOrder () {
      let options = []
      const defaultIsDisabled = false
      if (this.user.dashboardOrder && this.user.dashboardOrder.length) {
        options = options.concat(this.user.dashboardOrder.filter((option) => {
          return this.dashboardOrderOptions.find((dashboardObject) => dashboardObject.value === option.value)
        }))
      }
      const initialOptionsLength = options.length
      this.dashboardOrderOptions.slice().reverse().forEach((option) => {
        if (!options.find((dashboardObject) => dashboardObject.value === option.value)) {
          if (initialOptionsLength) {
            options.push({
              ...option,
              isDisabled: defaultIsDisabled,
            })
          } else {
            options.unshift({
              ...option,
              isDisabled: defaultIsDisabled,
            })
          }
        }
      })
      return options
    },
    canShowActionPanel () {
      return this.user.canAtAnySchool('propose tasks') || this.user.canAtAnySchool('log entries')
    },
  },
  methods: {
    startEditingDashboardOrder () {
      this.editDashboardOrder = this.dashboardOrder.map((dashboardObject) => {
        return {
          ...dashboardObject,
          isDisabled: dashboardObject.isDisabled || false,
        }
      })
       
      this.isCustomizeDashboardDialogVisible = true
    },
    async saveDashboardOrder () {
      this.user.dashboardOrder = this.editDashboardOrder
      this.isCustomizeDashboardDialogVisible = false
      this.runFunction('editUser', {
        userId: this.user.id,
        updatedUserData: this.user.toJson(),
      });
    },
    toggleDashboardOption (option) {
      option.isDisabled = !option.isDisabled
    },
    getInsightCountFromName (insightName) {
      return this.user.favoriteInsightQueries.filter((query) => query.insightName === insightName).length
    },
  },
  handleFailedPermission(router) {
    const user = store.state.app.user
    if (user) {
      if (user.getResolvedPermission({ canAtAnySchool: 'propose tasks|be proposed tasks' })) {
        router.replace('/tasks')
      } else {
        router.replace('/resources/links')
      }
    }
  },
};
</script>
