<template>
  <v-dialog
    :value="value"
    persistent
    max-width="720px"
    @input="$emit('input', $event)"
  >
    <v-card v-if="editUser">
      <v-progress-linear
        v-if="isSubmitting"
        indeterminate
      />
      <v-card-title class="flex-nowrap">
        <span v-if="!isCreating">
          Edit {{ isSelf ? 'Profile' : 'User' }}
        </span>
        <span v-else>
          Create User
        </span>
        <v-spacer />
        <v-btn
          icon
          color="grey darken-1"
          @click="$emit('input', false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="isOffline">
        You cannot edit users while offline. Please try again after reconnecting to the internet.
      </v-card-text>
      <v-card-text v-else>
        <v-form
          ref="editUserForm"
          v-model="isEditUserFormValid"
        >
          <v-card>
            <v-card-text>
              <div class="text-subtitle-1 mb-1">
                User Info
              </div>
              <v-text-field
                v-model="editUser.firstName"
                label="First Name"
                :rules="[rules.required]"
                class="mb-1"
                dense
                hide-details="auto"
                outlined
                required
              />

              <v-text-field
                v-model="editUser.lastName"
                label="Last Name"
                :rules="[rules.required]"
                class="mb-1"
                dense
                hide-details="auto"
                outlined
                required
              />

              <v-text-field
                v-model="editUser.email"
                label="Email"
                :rules="[rules.required, rules.email]"
                class="mb-1"
                dense
                hide-details="auto"
                outlined
                :disabled="!isCreating"
                required
              />

              <v-combobox
                ref="titleCombobox"
                v-model="editUser.title"
                :items="[
                  { header: 'Choose one of the default options or type your own.' },
                  ...titleOptions,
                ]"
                class="mb-1"
                hide-details="auto"
                label="Title"
                outlined
                dense
              />
            </v-card-text>
          </v-card>
          <v-card class="mt-2">
            <v-card-text>
              <div class="text-subtitle-1 mb-1">
                Tasks Settings
              </div>
              <v-checkbox
                v-model="editUser.isDistrictLevel"
                :disabled="editUser.id === user.id"
                hide-details="auto"
                label="This user can work tasks for all schools in the system"
              />
              <school-select
                v-if="!editUser.isDistrictLevel"
                v-model="editUser.roleSchoolIds"
                :disabled="editUser.id === user.id"
                class="mt-1"
                label="School(s) this user has access to"
                clearable
                multiple
              />
              <div class="text-caption mt-1">
                You can make this user a designated user for a task board if you want them to receive notifications
                for tasks assigned to that board. They can still see tasks from other boards.
              </div>
              <task-board-select
                v-model="editUser.assignedTaskBoardIds"
                label="Designated task boards"
                clearable
                class="mt-1"
                empty-label="None"
                multiple
                show-empty
              />
            </v-card-text>
          </v-card>
        </v-form>
        <div
          v-if="isCreating"
          class="text-body-1 mt-2"
        >
          NOTE: After submitting, the user will receive an email with instructions for setting up their account.
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="grey darken-1"
          text
          @click="$emit('input', false)"
        >
          Cancel
        </v-btn>
        <v-btn
          v-if="!isOffline"
          :disabled="isSubmitting"
          :loading="isSubmitting"
          color="primary"
          @click="submitEditUser()"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import SchoolSelect from '../common/SchoolSelect.vue';
import TaskBoardSelect from './TaskBoardSelect.vue';
import TasksSettings from './../../models/TasksSettings';

export default {
  name: 'EditTaskUserDialog',
  components: {
    SchoolSelect,
    TaskBoardSelect,
  },
  props: {
    userToEdit: Object,
    isCreating: Boolean,
    value: Boolean,
  },
  data() {
    return {
      isEditUserFormValid: true,
      isSubmitting: false,
      editUser: null,
      rules: {
        required: value => Boolean(value) || 'This field is required',
        email: value => {
          const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          return pattern.test(value) || 'Please enter a valid email';
        },
      },
      isManageMfaDialogVisible: false,
      expandedPanels: [],
      claimComplexityError: false,
    }
  },
  computed: {
    ...mapState('app', [
      'isOffline',
      'organizationSettings',
      'user',
      'tasksSettings',
    ]),
    titleOptions () {
      return [
        'Facilities Manager',
        'Technician',
        'Custodian',
        'Groundskeeper',
        'IT Specialist',
      ]
    },
    isSelf () {
      return !this.isCreating && this.user.id === this.editUser.id
    },
    schoolItems() {
      if (!this.organizationSettings) {
        return [];
      }
      return this.organizationSettings.schools.map((school) => {
        return {
          text: school.name,
          value: school.id,
        }
      })
    },
  },
  watch: {
    value: {
      handler() {
        if (this.value) {
          if (this.$refs.editUserForm) {
            this.$refs.editUserForm.resetValidation();
          }
          if (this.isCreating) {
            this.editUser = {
              firstName: '',
              lastName: '',
              email: '',
              title: '',
              roleSchoolIds: [],
              isDistrictLevel: true,
              assignedTaskBoardIds: [],
            };
          } else {
            const assignedTaskBoardIds = this.tasksSettings.boards
              .filter(board => board.assignedUserIds.includes(this.userToEdit.id))
              .map(board => board.id);
            this.editUser = {
              firstName: this.userToEdit.firstName,
              lastName: this.userToEdit.lastName,
              email: this.userToEdit.email,
              title: this.userToEdit.title,
              roleSchoolIds: this.userToEdit.roleSchoolIds,
              isDistrictLevel: this.userToEdit.isDistrictLevel,
              assignedTaskBoardIds,
              id: this.userToEdit.id,
            }
          }
        } else {
          this.expandedPanels = []
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('app', [
      'createUserFromObject',
      'showSuccess',
      'showError',
      'loadUsers',
    ]),
    ...mapMutations('app', [
      'setTasksSettings',
    ]),
    async submitEditUser () {
      if (this.$refs.titleCombobox) {
        this.$refs.titleCombobox.blur();
        await this.$nextTick();
      }
      if (this.$refs.editUserForm.validate() || this.isSubmitting) {
        this.isSubmitting = true
        try {
          if (this.isCreating) {
            // Perform all of this server-side for security reasons
            await this.runFunction('generateNewTaskUser', {
              userData: this.editUser,
            })

            this.showSuccess('User successfully created')
          } else {
            const updatedUserData = { ...this.editUser }
            const originalAssignedTaskBoardIds = this.tasksSettings.boards
              .filter(board => board.assignedUserIds.includes(this.userToEdit.id))
              .map(board => board.id);
            
            // if task boards did not change, delete them from update object
            if (originalAssignedTaskBoardIds.length === updatedUserData.assignedTaskBoardIds.length
              && originalAssignedTaskBoardIds.every(id => updatedUserData.assignedTaskBoardIds.includes(id))) {
              delete updatedUserData.assignedTaskBoardIds;
            }
            await this.runFunction('editTaskUser', {
              userId: this.userToEdit.id,
              updatedUserData: {
                ...this.editUser,
              },
            });

            this.showSuccess('Changes saved successfully');
          }

          const updatedTasksSettings = await TasksSettings.getById('tasks')
          this.setTasksSettings(updatedTasksSettings)
          await this.loadUsers()
          this.editUser = null;
          this.$emit('refresh')
        } catch (e) {
          console.error(e)
          this.showError({ message: `There was an error while ${this.isCreating ? 'creating' : 'editing'} the user.` })
        } finally {
          this.isSubmitting = false
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
