<template>
  <v-chip
    :color="printing ? '#666' : '#ddd'"
    :outlined="printing"
    small
  >
    <v-icon
      :color="priorityItem.color"
      class="mr-1"
      small
    >
      mdi-flag
    </v-icon>
    <span class="font-weight-bold">{{ priorityItem.text }}{{ printing ? ' Priority' : '' }}</span>
  </v-chip>
</template>

<script>
export default {

  props: {
    priority: {
      type: String,
      required: true,
    },
    printing: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    priorityItem () {
      return {
        Low: {
          text: 'Low',
          color: '#007BFF',
        },
        Medium: {
          text: 'Medium',
          color: '#E0B000',
        },
        High: {
          text: 'High',
          color: '#FD7E14',
        },
        Critical: {
          text: 'Critical',
          color: '#DC3545',
        },
      }[this.priority] || {
        text: 'Unknown',
        color: '#6C757D',
      }
    },
  },
}
</script>

<style>

</style>