<template>
  <v-card v-if="observationSettings">
    <v-container>
      <v-row>
        <v-col
          v-if="showLinked"
          class="pt-0 pb-0 pl-2"
          cols="1"
        >
          <v-simple-checkbox
            color="primary"
            :readonly="readonly"
            :disabled="readonly"
            :value="linked"
            @input="link($event)"
          />
        </v-col>
        <v-col :class="{'pt-0': true, 'pb-0': true, 'pl-2': showLinked}">
          <div
            class="mb-1"
            style="display: flex"
          >
            <div
              class="text-subtitle-1 mb-1"
              style="flex: 1"
            >
              Entry <span v-if="observationNumber">#{{ observationNumber }}</span> ({{ observationTypeLabels[observation.observationType] }})
              <div class="text-caption">
                {{ observation.timestamp | formatDate("M/D/YYYY h:mma") }}
              </div>
            </div>
            <v-menu
              v-if="!readonly && (canManage || (!notCreated && !noView))"
              top
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-if="!notCreated && !noView">
                  <v-btn
                    text
                    @click="$router.push(`/entries/${observation.id}`)"
                  >
                    <v-list-item-title>View entry</v-list-item-title>
                  </v-btn>
                </v-list-item>
                <template v-if="canManage">
                  <v-list-item>
                    <v-btn
                      text
                      @click="startEditing()"
                    >
                      <v-list-item-title>Edit entry</v-list-item-title>
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-btn
                      text
                      color="error"
                      @click="startDeleting()"
                    >
                      <v-list-item-title>Delete entry</v-list-item-title>
                    </v-btn>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </div>
          <div
            v-if="showSchool && observation.schoolId && !printing"
            class="text-caption"
          >
            <router-link
              :to="`/schools/${observation.schoolId}`"
            >
              {{ getSchoolById(observation.schoolId).name }}
            </router-link>
          </div>
          <!-- <div v-if="showWalk && observation.walkId" class="text-caption">
            <router-link :to="`/walks/walk/${observation.walkId}/complete`">
              View Associated SchoolDog Walk
            </router-link>
          </div> -->
          <div
            v-if="observation.reportedByUserId && !printing"
            class="text-caption"
          >
            <div>
              Logged by <user-link
                :user-id="observation.reportedByUserId"
              />
            </div>
          </div>
        </v-col>
      </v-row>
      <div
        v-if="showSchool && observation.schoolId && printing"
        class="text-body-2 mb-1"
      >
        <div class="font-weight-thin text-caption">
          School
        </div>
        <div class="font-weight-medium">
          {{ getSchoolById(observation.schoolId) ? getSchoolById(observation.schoolId).name : 'Unknown' }}
        </div>
      </div>
      <div
        v-if="observation.reportedByUserId && printing"
        class="text-body-2 mb-1"
      >
        <div class="font-weight-thin text-caption">
          Logged by
        </div>
        <div class="font-weight-medium">
          <user-link
            :user-id="observation.reportedByUserId"
            printing
          />
        </div>
      </div>
      <div class="text-body-2 mb-1">
        <div class="font-weight-thin text-caption">
          Category
        </div>
        <div class="font-weight-medium">
          {{ observationCategoryById[observation.observationCategoryId].label }}
        </div>
      </div>
      <div class="text-body-2 mb-1">
        <div class="font-weight-thin text-caption">
          Observation
        </div>
        <div class="font-weight-medium">
          {{ observationItemById[observation.observationItemId].label }}
        </div>
      </div>
      <div
        v-if="observation.quantity && observation.quantity.toString() !== '1'"
        class="text-body-2 mb-1"
      >
        <div class="font-weight-thin text-caption">
          Quantity
        </div>
        <div class="font-weight-medium">
          {{ observation.quantity }}
        </div>
      </div>
      <div class="text-body-2 mb-1">
        <div class="font-weight-thin text-caption">
          Location
        </div>
        <div class="font-weight-medium">
          {{ observation.location || 'N/A' }}
        </div>
      </div>
      <div class="text-body-2 mb-1">
        <div class="font-weight-thin text-caption">
          Description
        </div>
        <div class="font-weight-medium">
          {{ observation.comment || 'N/A' }}
        </div>
      </div>
      <div
        v-if="imageUrls && imageUrls.length"
        style="display: flex; justify-content: space-between; flex-wrap: wrap"
      >
        <div
          v-for="pictureUrl in imageUrls"
          :key="pictureUrl.url"
          style="max-width: calc(50% - 8px); width: calc(50% - 8px);"
        >
          <v-skeleton-loader
            :loading="pictureUrl.loading"
            type="image"
            class="ma-1"
          >
            <v-img
              :src="pictureUrl.url"
              class="max-width: 100%; max-height: 100%"
              @load="pictureUrl.loading = false"
              @error="pictureUrl.error = true"
            />
          </v-skeleton-loader>
          <div v-if="pictureUrl.loading && isOffline">
            <v-icon color="error">
              mdi-wifi-off
            </v-icon>
            Pictures cannot be displayed while offline
          </div>
        </div>
      </div>
      <div v-else-if="observation.imagePaths && observation.imagePaths.length && numTimesTriedLoadingImages < 4">
        <v-skeleton-loader
          :loading="true"
          type="image"
          class="ma-1"
        />
      </div>
      <div
        v-if="!printing"
        class="mt-1 mb-1"
      >
        <v-chip
          v-for="(tag, index) in observation.tags"
          :key="`tag-${index}`"
          class="tag"
        >
          {{ tag }}
        </v-chip>
      </div>
      <div v-if="!hideTasks">
        <div
          v-if="notCreated && observation.tasksToAddOnSubmission && observation.tasksToAddOnSubmission.length"
          class="text--secondary font-italic"
        >
          {{ `${observation.tasksToAddOnSubmission.length} ` }} task{{ observation.tasksToAddOnSubmission.length > 1 ? 's' : '' }} will be created when this entry is submitted
        </div>
        <div v-else-if="observation.taskId && tasksById[observation.taskId]">
          <a
            :href="`/tasks/task/${observation.taskId}`"
            target="_blank"
          >View Associated Task</a>
        </div>
      </div>
      <div v-if="showEditDisclaimer">
        <div
          class="text--secondary font-italic"
        >
          NOTE: This data may not reflect updates made to the entry after it was originally logged
        </div>
      </div>
    </v-container>

    <log-entry-dialog
      v-model="isEditDialogVisible"
      floating
      is-editing
      :no-view="noView"
      :initial-observation="observation"
      @save="updateFromSave($event)"
    />

    <v-dialog
      v-model="isDeleteDialogVisible"
      max-width="528px"
      persistent
    >
      <v-card>
        <v-card-title>Delete Entry</v-card-title>
        <v-card-text>
          Are you sure you want to permanently delete this entry? This action cannot be undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="!isDeleting"
            text
            color="grey darken-1"
            @click="isDeleteDialogVisible = false"
          >
            No, Cancel
          </v-btn>
          <v-btn
            color="error"
            :loading="isDeleting"
            :disabled="isDeleting"
            @click="handleDelete()"
          >
            Yes, Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import FirestoreService from '../../services/FirestoreService';
import UserLink from '../common/UserLink.vue';
const { ref, getDownloadURL } = require('firebase/storage');
export default {
  name: 'EntryCard',
  components: { UserLink },
  props: {
    observation: Object,
    observationNumber: Number,
    readonly: Boolean,
    showLinked: {
      type: Boolean,
      default: false,
    },
    linked: {
      type: Boolean,
      default: false,
    },
    hideTasks: Boolean,
    showSchool: Boolean,
    showWalk: Boolean,
    dontHandleState: Boolean,
    noView: Boolean,
    notCreated: Boolean,
    showEditDisclaimer: Boolean,
    printing: Boolean,
  },
  data() {
    return {
      observationTypeLabels: {
        concern: 'Concern',
        praise: 'Praise',
        other: 'Other',
      },
      imageUrls: [],
      numTimesTriedLoadingImages: 0,
      isEditDialogVisible: false,
      isDeleteDialogVisible: false,
      isDeleting: false,
    };
  },
  computed: {
    ...mapState('app', [
      'isOffline',
      'observationSettings',
      'tasksById',
      'user',
    ]),
    ...mapGetters('app', [
      'getSchoolById',
    ]),
    observationCategoryById() {
      const map = {};
      this.observationSettings.categories.forEach((observationCategory) => {
        map[observationCategory.id] = observationCategory;
      });
      return map;
    },
    observationItemById() {
      const map = {};
      this.observationSettings.items.forEach((observationItem) => {
        map[observationItem.id] = observationItem;
      });
      return map;
    },
    canManage () {
      return (this.observation.walkType === 'school' && this.$canAtSchool('manage entries', this.observation.schoolId))
        || (this.observation.walkType !== 'school' && this.$can('manage other entries'))
        || this.observation.reportedByUserId === this.user.id;
    },
  },
  watch: {
    'observation.imagePaths': {
      handler () {
        this.numTimesTriedLoadingImages = 0;
        this.setImages();
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('app', [
      'showSuccess',
    ]),
    link(event) {
      this.$emit('link', event)
    },
    async setImages () {
      this.numTimesTriedLoadingImages++
      if (this.numTimesTriedLoadingImages > 3) {
        return
      }
      if (this.observation.imagePaths && this.observation.imagePaths.length) {
        try {
          const imageUrls = await Promise.all(this.observation.imagePaths.map(async (imagePath) => {
            const imageRef = ref(this.storage, imagePath);
            const url = await getDownloadURL(imageRef);
            return {
              url,
              error: false,
              loading: false,
            };
          }));
          this.imageUrls = imageUrls.filter(url => url);
        } catch (e) {
          if (this.numTimesTriedLoadingImages < 5) {
            setTimeout(() => {
              this.setImages()
            }, this.numTimesTriedLoadingImages * 2000)
          }
        }
      } else {
        this.imageUrls = [];
      }
    },
    startEditing() {
      if (this.dontHandleState) {
        this.$emit('edit');
      } else {
        this.isEditDialogVisible = true;
      }
    },
    async handleDelete () {
      if (this.dontHandleState) {
        this.$emit('delete')
      } else {
        try {
          this.isDeleting = true;
          if (this.observation.delete) {
            if (this.isOffline) {
              this.observation.delete()
            } else {
              await this.observation.delete()
            }
          } else {
            await FirestoreService.deleteDocument('observations', this.observation.id);
          }
          this.showSuccess('Entry deleted successfully')
          this.$emit('delete')
          this.$emit('input', false)
        } finally {
          this.isDeleting = false;
          this.isDeleteDialogVisible = false
        }
      }
    },
    startDeleting() {
      this.isDeleteDialogVisible = true
    },
    updateFromSave(observation) {
      Object.assign(this.observation, observation);
    },
  },
};
</script>
<style lang="scss">
.menu-item-name {
  font-weight: bold;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
.tag {
  margin: 3px;
}
</style>
