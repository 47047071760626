import FirestoreModel from './FirestoreModel';

class TasksSettings extends FirestoreModel {
    static collectionName = 'settings';
    static allowedFields = [
      'customFields',
      'boards',
      'requiredFields',
      'defaultTaskBoard',
    ]

    constructor(id, data) {
      super(id, data);

      if (!this.boards) {
        this.boards = []
      }
      this.boards.forEach((board) => {
        board.assignedUserIds = board.assignedUserIds || []
      })
    }
}

export default TasksSettings;
