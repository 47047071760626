<template>
  <v-dialog
    v-if="tasksSettings && value"
    :value="value"
    persistent
    width="600"
    style="z-index: 10000"
    @input="$emit('input', $event)"
  >
    <v-card>
      <v-card-title>
        {{ isEdit ? 'Edit Task' : 'Create Task' }}
        <v-spacer />
        <v-btn
          icon
          @click="close()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form
          ref="taskForm"
          v-model="isTaskFormValid"
        >
          <div class="text-subtitle-1 mb-1">
            Required Fields
          </div>
          <school-select
            v-model="editTask.schoolId"
            class="mb-1"
            clearable
            outlined
            dense
            :rules="[v => !!v || 'School is required']"
            :permission="'propose tasks'"
            label="School"
            hide-details="auto"
          />

          <task-board-select
            v-if="tasksSettings.requiredFields.taskBoard"
            v-model="editTask.boardId"
            class="mb-1"
            :rules="[v => !!v || 'Task board is required']"
          />


          <user-multi-select
            v-if="tasksSettings.requiredFields.initialAssignedUsers"
            v-model="editTask.initialAssignedUsers"
            :disabled="!editTask.schoolId"
            :rules="[v => !!v || 'Assigned users are required']"
            :valid-user-ids="getEligibleTaskAssigneesForTask(editTask).map(user => user.value)"
            type="outlined"
            label="Assigned users"
            dense
            show-self
            hide-details="auto"
            class="mb-1"
          />

          <v-textarea
            v-model="editTask.description"
            class="mb-1"
            label="Description"
            hide-details="auto"
            outlined
            dense
            required
            clearable
            clear-icon="mdi-close"
            auto-grow
            :rows="3"
            :rules="[v => !!v || 'Description is required']"
          />

          <task-priority-select
            v-if="tasksSettings.requiredFields.priority"
            v-model="editTask.priority"
            class="mb-1"
            clearable
            :rules="[v => !!v || 'Priority is required']"
          />

          <observation-location-select
            v-if="tasksSettings.requiredFields.location"
            ref="locationCombobx"
            v-model="editTask.location"
            :rules="[v => !!v || 'Location is required']"
            :disabled="!editTask.schoolId"
            :school-id="editTask.schoolId"
            name="taskLocation"
            header-text="Select an existing location or type a new one."
            class="mb-1"
            clearable
          />
          <v-menu
            v-if="tasksSettings.requiredFields.dueDate"
            ref="menu"
            v-model="isDueDateMenuOpen"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template #activator="{ on }">
              <v-text-field
                v-model="editTask.dueDate"
                label="Requested completion date"
                outlined
                class="mb-1"
                dense
                clearable
                hide-details="auto"
                :rules="[v => !!v || 'Requested completion date is required']"
                readonly
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="editTask.dueDate"
              no-title
              scrollable
            >
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(editTask.dueDate)"
              >
                {{ $t('common.save') }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.cancel()"
              >
                {{ $t('common.cancel') }}
              </v-btn>
            </v-date-picker>
          </v-menu>

          <v-textarea
            v-if="tasksSettings.requiredFields.notes"
            v-model="editTask.notes"
            class="mb-1"
            label="Notes"
            hide-details="auto"
            outlined
            dense
            required
            auto-grow
            :rows="3"
            :rules="[v => !!v || 'Notes are required']"
          />

          <template v-if="editTask.customFields">
            <div
              v-for="(customField, customFieldIndex) in requiredCustomFields"
              :key="`requiredCustomField-${customFieldIndex}`"
              class="mb-1"
            >
              <v-text-field
                v-model="editTask.customFields[customField]"
                :label="customField"
                outlined
                dense
                hide-details="auto"
                maxlength="100"
                required
                :rules="[v => !!v || `${customField} is required`]"
              />
            </div>
          </template>

          <div class="text-subtitle-1 my-1">
            Optional Fields
          </div>
          <task-board-select
            v-if="!tasksSettings.requiredFields.taskBoard"
            v-model="editTask.boardId"
            class="mb-1"
            clearable
          />
          <user-multi-select
            v-if="!tasksSettings.requiredFields.initialAssignedUsers"
            v-model="editTask.initialAssignedUsers"
            :disabled="!editTask.schoolId"
            :valid-user-ids="getEligibleTaskAssigneesForTask(editTask).map(user => user.value)"
            type="outlined"
            dense
            label="Assigned users"
            show-self
            hide-details="auto"
            class="mb-1"
          />

          <task-priority-select
            v-if="!tasksSettings.requiredFields.priority"
            v-model="editTask.priority"
            class="mb-1"
            clearable
          />

          <observation-location-select
            v-if="!tasksSettings.requiredFields.location"
            ref="locationCombobx"
            v-model="editTask.location"
            :disabled="!editTask.schoolId"
            :school-id="editTask.schoolId"
            :name="isEdit ? 'editLocation' : 'newLocation'"
            header-text="Select an existing location or type a new one."
            class="mb-1"
            clearable
          />
          <v-menu
            v-if="!tasksSettings.requiredFields.dueDate"
            ref="menu"
            v-model="isDueDateMenuOpen"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template #activator="{ on }">
              <v-text-field
                v-model="editTask.dueDate"
                label="Requested completion date"
                outlined
                dense
                clearable
                class="mb-1"
                hide-details="auto"
                readonly
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="editTask.dueDate"
              no-title
              scrollable
            >
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(editTask.dueDate)"
              >
                {{ $t('common.save') }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.cancel()"
              >
                {{ $t('common.cancel') }}
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-textarea
            v-if="!tasksSettings.requiredFields.notes"
            v-model="editTask.notes"
            class="mb-1"
            label="Notes"
            hide-details="auto"
            outlined
            dense
            required
            auto-grow
            :rows="3"
          />
          <template v-if="editTask.customFields">
            <div
              v-for="(customField, customFieldIndex) in notRequiredCustomFields"
              :key="`notRequiredCustomField-${customFieldIndex}`"
              class="mb-1"
            >
              <v-text-field
                v-model="editTask.customFields[customField]"
                :label="customField"
                outlined
                dense
                maxlength="100"
                hide-details="auto"
              />
            </div>
          </template>
          <compressed-image-input
            v-model="editTask.pictureFiles"
            multiple
          />
          <div
            v-if="editTask.observation && editTask.observation.schoolId && editTask.observation.observationType && editTask.observation.observationItemId && editTask.observation.observationCategoryId"
            class="text-subtitle-1 my-1"
          >
            Based On Entry
          </div>
          <entry-card
            v-if="editTask.observation && editTask.observation.schoolId && editTask.observation.observationType && editTask.observation.observationItemId && editTask.observation.observationCategoryId"
            readonly
            hide-tasks
            show-school
            :observation="editTask.observation"
          />
        </v-form>
        <v-checkbox
          v-if="forEntry && allowDelayedSave"
          v-model="shouldImmediatelySave"
          label="Create task immediately instead of when the SchoolDog Walk is completed"
        />
      </v-card-text>

      <v-card-actions class="pa-2">
        <v-spacer />
        <v-btn
          color="grey darken-1"
          text
          @click="close"
        >
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          :disabled="isSaving"
          :loading="isSaving"
          @click="save"
        >
          {{ isEdit ? 'Save' : (forEntry ? 'Add to entry' : 'Create') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import uuid from 'uuid'
import ImageUploadService from '@/services/ImageUploadService'
import CompressedImageInput from '../common/CompressedImageInput.vue'
import Task from '../../models/Task'
import SchoolSelect from '../common/SchoolSelect.vue'
import UserMultiSelect from '../common/UserMultiSelect.vue'
import TaskBoardSelect from './TaskBoardSelect.vue'
import ObservationLocationSelect from '../walks/ObservationLocationSelect.vue'
import TaskPrioritySelect from './TaskPrioritySelect.vue'
import EntryCard from '../walks/EntryCard.vue'

/*
|---------------------------------------------------------------------
| TODO Compose Component
|---------------------------------------------------------------------
|
| Compose new tasks editor
|
*/
export default {
  name: 'CreateTaskDialog',
  components: {
    SchoolSelect,
    UserMultiSelect,
    CompressedImageInput,
    TaskBoardSelect,
    ObservationLocationSelect,
    TaskPrioritySelect,
    EntryCard,
  },
  props: {
    // whether we want an input event or an actual save
    editing: Boolean,
    allowDelayedSave: Boolean,
    observation: {
      type: Object,
      required: false,
    },
    value: {
      type: Boolean,
      required: true,
    },
    initialTask: {
      type: Object,
      required: false,
    },
    initialDescription: {
      type: String,
      required: false,
    },
    initialSchoolId: {
      type: String,
      required: false,
    },
    forEntry: Boolean,
  },
  data () {
    return {
      title: '',
      description: '',
      editTask: {},

      isTaskFormValid: false,

      search: null,

      isDueDateMenuOpen: false,

      isSaving: false,

      shouldImmediatelySave: false,
    }
  },
  computed: {
    ...mapGetters('app', [
      'getEligibleTaskAssigneesForTask',
    ]),
    ...mapState('app', [
      'tasksSettings',
      'user',
      'users',
    ]),
    isEdit() {
      return this.editing || (this.editTask && this.editTask.id)
    },
    priorityItems () {
      return [
        'Low',
        'Medium',
        'High',
        'Critical',
      ]
    },
    customFields () {
      return this.tasksSettings.customFields || []
    },
    requiredCustomFields () {
      return this.customFields.filter(customField => this.tasksSettings.requiredFields[customField])
    },
    notRequiredCustomFields () {
      return this.customFields.filter(customField => !this.tasksSettings.requiredFields[customField])
    },
  },
  watch: {
    value () {
      if (this.value) {
        if (this.$refs.taskForm) {
          this.$refs.taskForm.resetValidation()
        }
        if (this.initialTask) {
          this.editTask = this.initialTask.clone()
          this.editTask.customFields = JSON.parse(JSON.stringify(this.initialTask.customFields))
        } else {
          this.editTask = {
            customFields: {},
          }
        }
        if (this.observation && !this.initialTask) {
          this.editTask.schoolId = this.observation.schoolId
          this.editTask.location = this.observation.location
          this.editTask.observation = { ...this.observation }
          delete this.editTask.observation.tasksToAddOnSubmission
          this.editTask.observation.reportedByUserId = this.editTask.observation.reportedByUserId || this.user.id
        }
        if (this.initialDescription && !this.editTask.description) {
          this.editTask.description = this.initialDescription
        }
        if (this.initialSchoolId && !this.editTask.schoolId) {
          this.editTask.schoolId = this.initialSchoolId
        }
        if (!this.editTask.customFields) {
          this.editTask.customFields = {}
        }
        if (!this.editTask.boardId && this.tasksSettings.defaultTaskBoard) {
          if (this.tasksSettings.boards.some(board => board.id === this.tasksSettings.defaultTaskBoard)) {
            this.editTask.boardId = this.tasksSettings.defaultTaskBoard
          }
        }
      }
    },
  },
  methods: {
    ...mapActions('app', [
      'showSuccess',
    ]),
    ...mapMutations('app', [
      'addTaskCreatedInSession',
    ]),
    async save () {
      await this.$refs.taskForm.validate()
      if (this.isTaskFormValid) {
        try {
          this.isSaving = true
          if (this.editTask.pictureFiles) {
            const newPictures = await this.uploadPictures(this.editTask);
            this.editTask.imagePaths =
            [
              ...(this.editTask.imagePaths || []),
              ...newPictures,
            ]
          }
          delete this.editTask.pictureFiles;
          const task = this.initialTask || new Task(null, {
            status: 'New',
            createdByUserId: this.user.id,
            assignedUserIdsMap: {},
            followingUserIdsMap: {
              [this.user.id]: true,
            },
          })
          
          Object.keys(this.editTask).forEach((key) => {
            task[key] = this.editTask[key]
          })
          this.close()
          if (this.editTask.initialAssignedUsers) {
            task.setAssignedUserIds(this.user.id, this.editTask.initialAssignedUsers, !this.isEdit, true)
          }
          if (this.forEntry) {
            this.$emit('save', {task, shouldImmediatelySave: !this.allowDelayedSave || this.shouldImmediatelySave})
          } else {
            await task.save()
            this.addTaskCreatedInSession(task)
            this.showSuccess(`Task ${this.isEdit ? 'updated' : 'created'} successfully`)
          }
        } finally {
          this.isSaving = false
        }
      }
    },
    close () {
      this.$refs.taskForm.resetValidation()
      this.$emit('input', false)
    },
    async uploadPictures() {
      if (!this.editTask.pictureFiles) {
        return [];
      }

      const imagePaths = [];

      this.editTask.pictureFiles.forEach((file) => {
        const storageRef = ImageUploadService.beginFileUpload(`tasks/${this.user.id}/${uuid()}`, file);
        imagePaths.push(storageRef.fullPath);
      });

      return imagePaths;
    },
  },
}
</script>
