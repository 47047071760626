<template>
  <div v-if="user">
    <v-menu
      offset-y
      left
      transition="slide-y-transition"
      class="pa-1"
      min-width="200px"
    >
      <template #activator="{ on }">
        <v-btn
          icon
          class="elevation-2"
          style="width: 36px; height: 36px"
          v-on="on"
        >
          <user-avatar
            prevent-click
            :user-id="user.id"
            size="36"
          />
        </v-btn>
      </template>

      <!-- user menu list -->
      <v-list
        dense
        nav
      >
        <v-list-item @click="isEditUserDialogVisible = true">
          <v-list-item-icon>
            <v-icon small>
              mdi-cog
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Edit Profile</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="toggleOfflineMode()">
          <v-list-item-icon>
            <v-icon small>
              {{ isManuallyOffline ? 'mdi-wifi' : 'mdi-wifi-off' }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ isManuallyOffline ? 'Disable' : 'Enable' }} Offline Mode</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="startLogOut()">
          <v-list-item-icon>
            <v-icon small>
              mdi-logout-variant
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('menu.logout') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <edit-user-dialog
      v-model="isEditUserDialogVisible"
      :user-to-edit="user"
      @refresh="refreshUser()"
    />
    <v-dialog
      v-model="isOfflineDialogVisible"
      max-width="528px"
    >
      <v-card v-if="user">
        <v-card-title>
          Changes may not be saved
          <v-spacer />
          <v-btn
            icon
            @click="isOfflineDialogVisible = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          You are attempting to sign out while offline. For security reasons, any changes you have made since your last successful sync will be purged. Please ensure all important data is synced to the internet before signing out.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="isOfflineDialogVisible = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="doLogOut()"
          >
            Sign out and lose unsaved changes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import UserAvatar from '../common/UserAvatar.vue';
import EditUserDialog from './../manageUsers/EditUserDialog.vue'
/*
|---------------------------------------------------------------------
| Toolbar User Component
|---------------------------------------------------------------------
|
| Quickmenu for user menu shortcuts on the toolbar
|
*/
export default {
  name: 'ToolbarUser',
  components: {
    EditUserDialog,
    UserAvatar,
  },
  data() {
    return {
      isEditUserDialogVisible: false,
      isOfflineDialogVisible: false,
    }
  },
  computed: {
    ...mapState('app', [
      'isOffline',
      'isManuallyOffline',
      'user',
    ]),
  },
  methods: {
    ...mapActions({
      setUserFromFirebaseUser: 'app/setUserFromFirebaseUser',
      logOut: 'app/logOut',
      setManualOfflineMode: 'app/setManualOfflineMode',
    }),
    refreshUser () {
      this.setUserFromFirebaseUser()
      this.isEditUserDialogVisible = false
    },
    async startLogOut() {
      if (this.isOffline) {
        this.isOfflineDialogVisible = true
        return
      }
      await this.logOut()
    },
    async doLogOut() {
      await this.logOut()
    },
    toggleOfflineMode () {
      this.setManualOfflineMode(!this.isManuallyOffline)
    },
  },
}
</script>
