<template>
  <v-select
    v-bind="$attrs"
    :value="value"
    :items="items"
    :label="label"
    hide-details="auto"
    dense
    outlined
    required
    @input="$emit('input', $event)"
  >
    <template #selection="{ item }">
      <task-board-chip
        v-if="item.value"
        :board-id="item.value"
      />
      <div v-else>
        {{ item.text }}
      </div>
    </template>
    <template #item="{ item }">
      <task-board-chip
        v-if="item.value"
        :board-id="item.value"
      />
      <div v-else>
        {{ item.text }}
      </div>
    </template>
  </v-select>
</template>

<script>
import { mapState } from 'vuex'
import TaskBoardChip from './TaskBoardChip.vue'
export default {

  components: {
    TaskBoardChip,
  },

  props: {
    value: {
      type: [
        String,
        Array,
      ],
    },
    label: {
      type: String,
      default: 'Task board',
    },
    showEmpty: Boolean,
    emptyLabel: {
      type: String,
      default: 'None',
    },
  },

  computed: {
    ...mapState('app', [
      'tasksSettings',
      'user',
    ]),
    taskBoard () {
      return this.tasksSettings.board.find(board => board.id === this.boardId)
    },
    filteredTaskBoards() {
      if (!this.tasksSettings) {
        return []
      }
      return this.tasksSettings.boards.filter(board => {
        if (this.validTaskBoardIds && !this.validTaskBoardIds.includes(board.id)) {
          return false
        }
        return this.user.can('manage tasks settings') || !board.preventNonAssignedUsers || board.assignedUserIds?.includes(this.user.id)
      })
    },
    items () {
      return [
        ...((this.tasksSettings.requiredFields.taskBoard && !this.showEmpty) ? [] : [
          { text: this.emptyLabel, value: null },
        ]),
        ...this.filteredTaskBoards.map(board => ({
          text: board.name,
          value: board.id,
        })),
      ]
    },
  },
}
</script>