<template>
  <div
    v-if="tasksSettings"
    class="d-flex"
    style="width: 100%; max-width: 100%;"
  >
    <div
      class="flex-grow-1"
      style="width: 100%; max-width: 100%; margin-bottom: 64px;"
    >
      <div class="d-flex flex-column flex-grow-1 px-4 pb-4 pt-2">
        <div class="text-h5 mb-1">
          Tasks Settings
        </div>
        <div class="text-subtitle-2 mb-1">
          Configure how tasks get created and sent to users.
        </div>
        <div class="mt-2">
          <v-card>
            <v-card-title>
              Task Boards
              <v-spacer />
              <v-menu left>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    icon
                    v-on="on"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-btn
                      text
                      @click="startReorderingTaskBoards()"
                    >
                      <v-list-item-title>Reorder task boards</v-list-item-title>
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-btn
                      text
                      @click="startEditingDefaultTaskBoard()"
                    >
                      <v-list-item-title>Set default task board</v-list-item-title>
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-card-title>
            <v-card-subtitle>
              Task Boards organize tasks and allow tasks to be viewed and worked on by the right people.
            </v-card-subtitle>
            <v-card-text>
              <div class="d-flex align-center justify-center">
                <v-text-field
                  v-model="searchQuery"
                  append-icon="mdi-magnify"
                  solo
                  hide-details="auto"
                  dense
                  clearable
                  placeholder="Search"
                  class="ma-2"
                />
                <v-btn
                  color="primary"
                  @click="startAddingTaskBoard()"
                >
                  <v-icon left>
                    mdi-plus
                  </v-icon>
                  Add task board
                </v-btn>
              </div>
              <v-data-table
                :headers="taskBoardsHeaders"
                :items="taskBoardsItems"
                mobile-breakpoint="0"
              >
                <template #item.name="{ item }">
                  <task-board-chip
                    :color="item.color"
                    :name="item.name"
                  />
                </template>
                <template #item.assignedUserIds="{ item }">
                  <div
                    v-if="item.assignedUserIds.length"
                    class="d-flex align-center"
                  >
                    <v-chip
                      v-if="item.assignedUserIds.length > 10 || (!$vuetify.breakpoint.mdAndUp && item.assignedUserIds.length)"
                      small
                      class="mr-1"
                    >
                      <v-avatar
                        size="24"
                      >
                        <v-icon>
                          mdi-account-multiple
                        </v-icon>
                      </v-avatar>
                      <span class="ml-1">
                        {{ item.assignedUserIds.length }}
                      </span>
                    </v-chip>
                    <div
                      v-else-if="item.assignedUserIds.length"
                      class="d-flex flex-wrap"
                    >
                      <div
                        v-for="userId in item.assignedUserIds.slice(0, 10)"
                        :key="userId"
                        class="py-1 pr-1"
                      >
                        <user-avatar
                          :user-id="userId"
                          size="32"
                        />
                      </div>
                    </div>
                    <div
                      v-else
                      class="text-body-1"
                    >
                      N/A
                    </div>
                  </div>
                </template>
                <template #item.actions="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="startEditingTaskBoard(item)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </div>

        <div class="mt-2">
          <v-card>
            <v-card-title>
              Task Users
            </v-card-title>
            <v-card-subtitle>
              Users whose primary responsibility in SchoolDog is to work on tasks or who are designated users for a task board
            </v-card-subtitle>
            <v-card-text>
              <div class="d-flex align-center justify-center">
                <v-text-field
                  v-model="taskUsersSearchQuery"
                  append-icon="mdi-magnify"
                  solo
                  hide-details="auto"
                  dense
                  clearable
                  placeholder="Search"
                  class="ma-2"
                />
                <v-btn
                  color="primary"
                  @click="startAddingTaskUser()"
                >
                  <v-icon left>
                    mdi-plus
                  </v-icon>
                  Create task user
                </v-btn>
              </div>
              <v-data-table
                :headers="taskUsersHeaders"
                :items="taskUsersItems"
                :search="taskUsersSearchQuery"
                filter-mode="union"
                mobile-breakpoint="0"
              >
                <template #item.name="{ item }">
                  <div class="d-flex align-center">
                    <user-link
                      :user-id="item.id"
                    />
                    <span class="ml-2">
                      {{ item.name }}
                    </span>
                  </div>
                </template>
                <template #item.email="{ item }">
                  <copy-label :text="item.email" />
                </template>
                <template #[`item.school`]="{ item }">
                  <div v-if="item.isSchoolDogStaff">
                    SchoolDog Staff
                  </div>
                  <div v-if="['schooldog_site_admin', 'district_safety_leader'].includes(item.role) || item.isDistrictLevel">
                    District-Level
                  </div>
                  <div v-else>
                    <router-link
                      v-for="(schoolId, index) in item.roleSchoolIds"
                      :key="`${item.id}-${schoolId}-${index}`"
                      :to="`/schools/${schoolId}`"
                    >
                      {{ getSchoolById(schoolId) ? getSchoolById(schoolId).name : 'Unknown' }}<br>
                    </router-link>
                  </div>
                </template>
                <template #item.taskBoards="{ item }">
                  <div
                    v-if="taskBoardsByUserId[item.id]"
                    class="d-flex flex-wrap"
                  >
                    <task-board-chip
                      v-for="board in taskBoardsByUserId[item.id]"
                      :key="`${item.id}-${board.id}`"
                      :board-id="board.id"
                      class="mr-1 mb-1"
                    />
                  </div>
                  <div
                    v-else
                    class="text-body-1"
                  >
                    N/A
                  </div>
                </template>
                <template #item.action="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="startEditingUser(item)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </div>

        <div class="mt-2">
          <v-card>
            <v-card-title>
              Custom Fields
              <v-spacer />
              <v-btn
                v-if="!isEditingCustomFields"
                color="primary"
                icon
                @click="startEditingCustomFields()"
              >
                <v-icon>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-subtitle>
              Enable custom fields to ensure you always have the data you need when creating or working on tasks. Responses will be free text capped at 100 characters. You can enable up to 10 custom fields.
            </v-card-subtitle>
            <v-card-text v-if="isEditingCustomFields">
              <div
                v-if="editCustomFields.length"
                class="mb-2"
              >
                <div
                  v-for="field in editCustomFields"
                  :key="field"
                  class="d-flex align-center"
                >
                  <v-btn
                    icon
                    color="error"
                    @click="editCustomFields.splice(editCustomFields.indexOf(field), 1)"
                  >
                    <v-icon>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                  <span class="text-body-1 ml-1">{{ field }}</span>
                </div>
              </div>
              <div class="d-flex">
                <v-text-field
                  v-model="newCustomField"
                  label="Add new custom field"
                  outlined
                  hide-details="auto"
                  dense
                  class="mb-1"
                />
                <v-btn
                  color="primary"
                  :disabled="!newCustomField || editCustomFields.length >= 10"
                  class="ml-2"
                  rounded
                  @click="addCustomField()"
                >
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </div>
            </v-card-text>
            <v-card-text v-else>
              <div
                v-if="tasksSettings.customFields.length === 0"
                class="mb-2"
              >
                <span class="text-body-1">No custom fields enabled.</span>
              </div>
              <div
                v-for="field in tasksSettings.customFields"
                :key="field"
                class="d-flex align-center"
              >
                <v-icon
                  color="success"
                >
                  mdi-check
                </v-icon>
                <span class="text-body-1 ml-1">{{ field }}</span>
              </div>
            </v-card-text>
            <v-card-actions v-if="isEditingCustomFields">
              <v-spacer />
              <v-btn
                v-if="isEditingCustomFields"
                color="grey darken-1"
                text
                @click="isEditingCustomFields = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="saveCustomFields()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>

        <div class="mt-2">
          <v-card>
            <v-card-title>
              Required Fields
              <v-spacer />
              <v-btn
                v-if="!isEditingRequiredFields"
                color="primary"
                icon
                @click="startEditingRequiredFields()"
              >
                <v-icon>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-subtitle>
              Configure which fields are required when creating a task (school and description are always required).
            </v-card-subtitle>
            <v-card-text>
              <div
                v-for="item in requiredFieldItems"
                :key="item.value"
                class="mb-1"
              >
                <v-switch
                  v-if="isEditingRequiredFields"
                  v-model="editRequiredFields[item.value]"
                  hide-details="auto"
                  :label="item.text"
                />
                <div
                  v-else
                  class="d-flex align-center"
                >
                  <v-icon
                    v-if="tasksSettings.requiredFields[item.value]"
                    color="success"
                  >
                    mdi-check
                  </v-icon>
                  <v-icon
                    v-else
                    color="error"
                  >
                    mdi-close
                  </v-icon>
                  <span class="text-body-1 ml-1">{{ item.text }}</span>
                </div>
              </div>
            </v-card-text>
            <v-card-actions v-if="isEditingRequiredFields">
              <v-spacer />
              <v-btn
                color="grey darken-1"
                text
                @click="isEditingRequiredFields = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="saveRequiredFields()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="isEditTaskBoardDialogVisible"
      max-width="600"
    >
      <v-card>
        <v-card-title>
          {{ isEditingTaskBoard ? 'Edit' : 'Add' }} task board
          <v-spacer />
          <v-btn
            icon
            color="grey darken-1"
            @click="isEditTaskBoardDialogVisible = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="taskBoardForm"
            v-model="isAddTaskFormValid"
          >
            <v-text-field
              v-model="newTaskBoard.name"
              label="Task board name"
              outlined
              required
              hide-details="auto"
              maxlength="30"
              dense
              class="mb-2"
              :rules="[rules.required]"
            />
            <v-select
              v-model="newTaskBoard.color"
              :disabled="!newTaskBoard.name"
              :items="colorOptions"
              :rules="[rules.required]"
              label="Color"
              hide-details="auto"
              dense
              class="mb-2"
              outlined
              required
            >
              <template #selection="{ item }">
                <task-board-chip
                  :color="item.value"
                  :name="item.text"
                />
              </template>
              <template #item="{ item }">
                <task-board-chip
                  :color="item.value"
                  :name="item.text"
                />
              </template>
            </v-select>
            <div class="text-subtitle-2 mb-1">
              You can designate users to work this task board and receive notifications when unassigned tasks are created. Other users may also have access to these tasks depending on their access permissions.
            </div>
            <users-select
              v-model="newTaskBoard.assignedUserIds"
              label="Designated users"
              hide-details="auto"
              multiple
              show-self
            />
            <div class="d-flex align-center mt-1">
              <v-switch
                v-model="newTaskBoard.preventNonAssignedUsers"
                hide-details="auto"
                class="mr-1 mt-0"
                label="Only designated users should view this task board"
              />
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon
                    color="primary"
                    v-on="on"
                  >
                    mdi-alert-circle-outline
                  </v-icon>
                </template>
                <div style="max-width: 300px;">
                  If enabled, only designated users will be able to see this task board on their tasks page. However, other users still might be able to access individual tasks depending on their access permissions. It will also not restrict yourself or other users who can edit tasks settings from viewing the task board.
                </div>
              </v-tooltip>
            </div>
            <div class="text-center mt-3">
              <v-btn
                v-if="isEditingTaskBoard"
                color="error"
                text
                @click="isConfirmDeleteDialogVisible = true"
              >
                Delete task board
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="!isEditTaskBoardLoading"
            color="grey darken-1"
            text
            @click="isEditTaskBoardDialogVisible = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!isAddTaskFormValid || isEditTaskBoardLoading"
            :loading="isEditTaskBoardLoading"
            @click="saveTaskBoard()"
          >
            {{ isEditingTaskBoard ? 'Save' : 'Add' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="isConfirmDeleteDialogVisible"
      max-width="428"
      persistent
    >
      <v-card>
        <v-card-title>
          Confirm delete
          <v-spacer />
        </v-card-title>
        <v-card-text>
          Are you sure you want to delete this task board? This action cannot be undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="isConfirmDeleteDialogVisible = false"
          >
            No, Cancel
          </v-btn>
          <v-btn
            :disabled="isEditTaskBoardLoading"
            :loading="isEditTaskBoardLoading"
            color="error"
            @click="deleteTaskBoard()"
          >
            Yes, Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="isReorderTaskBoardsDialogVisible"
      max-width="428px"
    >
      <v-card>
        <v-card-title>
          Reorder task boards
          <v-spacer />
          <v-btn
            icon
            color="grey darken-1"
            @click="isReorderTaskBoardsDialogVisible = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <vuedraggable
            v-model="editTaskBoardOrder"
            handle=".handle"
          >
            <div
              v-for="(board) in editTaskBoardOrder"
              :key="board.id"
              class="mb-1"
            >
              <v-icon
                class="handle"
                style="cursor: move;"
              >
                mdi-drag-vertical
              </v-icon>
              <task-board-chip :board-id="board.id" />
            </div>
          </vuedraggable>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="isReorderTaskBoardsDialogVisible = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="saveTaskBoardOrder()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="isEditDefaultTaskBoardDialogVisible"
      max-width="428px"
    >
      <v-card>
        <v-card-title>
          Set default task board
          <v-spacer />
          <v-btn
            icon
            color="grey darken-1"
            @click="isEditDefaultTaskBoardDialogVisible = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle>
          You can optionally set a default task board to assign tasks to when creating a new task. Users can still change the task board when creating a task, and it can be updated after a task is created.
        </v-card-subtitle>
        <v-card>
          <v-card-text>
            <task-board-select
              v-model="editDefaultTaskBoard"
              label="Default task board"
              show-empty
              hide-details="auto"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="grey darken-1"
              text
              @click="isEditDefaultTaskBoardDialogVisible = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              :disabled="isEditTaskBoardLoading"
              :loading="isEditTaskBoardLoading"
              @click="saveDefaultTaskBoard()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
    <edit-task-user-dialog
      v-model="isEditTaskUserDialogVisible"
      :is-creating="!editingTaskUser"
      :user-to-edit="editingTaskUser"
      @refresh="isEditTaskUserDialogVisible = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import TaskBoardChip from '../../components/tasks/TaskBoardChip.vue';
import TaskBoardSelect from '../../components/tasks/TaskBoardSelect.vue';
import EditTaskUserDialog from '../../components/tasks/EditTaskUserDialog.vue';
import vuedraggable from 'vuedraggable';
import UsersSelect from '../../components/common/UsersSelect.vue';
import UserAvatar from '../../components/common/UserAvatar.vue';
import UserLink from '../../components/common/UserLink.vue';
import CopyLabel from './../../components/common/CopyLabel.vue';
const colors = [
  '#b24531', // dark red
  '#4444FF', // blue
  '#008000', // green
  '#800080', // purple
  '#EE9400', // orange
  '#008080', // teal
  '#4c4c00', // olive
  '#8B4513', // brown
  '#000080',  // navy
  '#888888', // grey
];

export default {
  name: 'TasksSettingsPage',
  components: {
    TaskBoardChip,
    vuedraggable,
    UsersSelect,
    UserAvatar,
    TaskBoardSelect,
    UserLink,
    CopyLabel,
    EditTaskUserDialog,
  },
  data () {
    return {
      isAddTaskFormValid: false,
      isEditTaskBoardDialogVisible: false,
      newTaskBoard: {
        name: '',
        color: '',
        assignedUserIds: [],
        preventNonAssignedUsers: false,
      },
      searchQuery: '',
      rules: {
        required: value => Boolean(value) || 'Required.',
      },
      isEditTaskBoardLoading: false,
      isEditingTaskBoard: false,
      isConfirmDeleteDialogVisible: false,
      isEditingRequiredFields: false,
      isReorderTaskBoardsDialogVisible: false,
      editTaskBoardOrder: [],
      editRequiredFields: null,
      editCustomFields: [],
      isEditingCustomFields: false,
      newCustomField: '',
      isEditDefaultTaskBoardDialogVisible: false,
      editDefaultTaskBoard: null,
      isEditTaskUserDialogVisible: false,
      editingTaskUser: null,
      taskUsersSearchQuery: '',
    };
  },
  computed: {
    ...mapState('app', [
      'tasksSettings',
      'user',
      'users',
      'usersById',
    ]),
    ...mapGetters('app', [
      'getSchoolById',
    ]),
    taskBoardsHeaders () {
      return [
        { text: 'Name', value: 'name' },
        { text: this.$vuetify.breakpoint.mdAndUp ? 'Designated users' : 'Users', value: 'assignedUserIds', sortable: false },
        { value: 'actions', sortable: false },
      ];
    },
    taskBoardsItems () {
      return this.tasksSettings.boards.map((board) => ({
        ...board,
        assignedUserIds: board.assignedUserIds || [],
      }));
    },
    colorOptions () {
      return colors.map((color) => ({
        text: this.newTaskBoard.name,
        value: color,
      }));
    },
    requiredFieldItems () {
      return [        
        { text: 'Assigned users', value: 'initialAssignedUsers' },
        { text: 'Location', value: 'location' },
        { text: 'Notes', value: 'notes' },
        { text: 'Priority', value: 'priority' },
        { text: 'Requested completion date', value: 'dueDate' },
        { text: 'Task board', value: 'taskBoard' },
        ...this.tasksSettings.customFields.map((field) => ({
          text: field,
          value: field,
        })),
      ];
    },
    taskUsersItems () {
      return this.users.filter(user => {
        if (user.isPrimarilyTaskUser) {
          return true;
        }
        if (this.taskBoardsByUserId[user.id]) {
          return true;
        }
        return false;
      })
    },
    taskUsersHeaders () {
      return [
        {
          text: 'Name',
          value: 'name',
          filter: (value, search, item) => {
            return `${item.firstName} ${item.lastName}`.toLowerCase().includes(search.toLowerCase())
          } },
        { text: 'Email', value: 'email' },
        {
          text: 'School',
          value: 'school',
        },
        { text: 'Task Boards', value: 'taskBoards' },
        { text: '', sortable: false, align: 'right', value: 'action' },
      ];
    },
    taskBoardsByUserId () {
      return this.tasksSettings.boards.reduce((acc, board) => {
        (board.assignedUserIds || []).forEach(userId => {
          if (!acc[userId]) {
            acc[userId] = [];
          }
          acc[userId].push(board);
        });
        return acc;
      }, {});
    },
  },
  methods: {
    ...mapActions('app', [
      'showSuccess',
    ]),
    startAddingTaskBoard () {
      this.isEditingTaskBoard = false;
      this.newTaskBoard = {
        name: '',
        color: '',
        assignedUserIds: [],
        preventNonAssignedUsers: false,
      };
      if (this.$refs.taskBoardForm) {
        this.$refs.taskBoardForm.resetValidation();
      }
      this.isEditTaskBoardDialogVisible = true;
    },
    async addTaskBoard () {
      this.tasksSettings.boards.push({
        ...this.newTaskBoard,
        id: this.generateFirestoreId(),
      });
      this.isEditTaskBoardLoading = true;
      try {
        await this.tasksSettings.save();
        this.isEditTaskBoardDialogVisible = false;
        this.showSuccess(`Task board "${this.newTaskBoard.name}" added.`);
      } finally {
        this.isEditTaskBoardLoading = false;
      }
    },
    generateFirestoreId() {
      return Math.random().toString(36).substring(2, 22);
    },
    startEditingTaskBoard (taskBoard) {
      this.newTaskBoard = JSON.parse(JSON.stringify(taskBoard));
      this.isEditingTaskBoard = true;
      this.isEditTaskBoardDialogVisible = true;
    },
    async saveTaskBoard () {
      if (this.isEditingTaskBoard) {
        const taskBoard = this.tasksSettings.boards.find(board => board.id === this.newTaskBoard.id);
        if (!taskBoard) {
          return;
        }
        Object.assign(taskBoard, this.newTaskBoard);
        try {
          this.isEditTaskBoardLoading = true;
          await this.tasksSettings.save();
          this.isEditTaskBoardDialogVisible = false;
        } finally {
          this.isEditTaskBoardLoading = false;
          this.showSuccess(`Task board "${this.newTaskBoard.name}" updated.`);
        } 
      } else {
        this.addTaskBoard();
      }
    },
    async deleteTaskBoard () {
      const taskBoard = this.tasksSettings.boards.find(board => board.id === this.newTaskBoard.id);
      if (!taskBoard || !this.isEditingTaskBoard || !this.isConfirmDeleteDialogVisible) {
        return;
      }
      const index = this.tasksSettings.boards.indexOf(taskBoard);
      this.tasksSettings.boards.splice(index, 1);
      try {
        this.isEditTaskBoardLoading = true;
        await this.tasksSettings.save();
        this.isConfirmDeleteDialogVisible = false;
        this.isEditTaskBoardDialogVisible = false;
        this.showSuccess(`Task board "${this.newTaskBoard.name}" deleted.`);
      } finally {
        this.isEditTaskBoardLoading = false;
      }
    },
    async saveRequiredFields () {
      try {
        this.isEditTaskBoardLoading = true;
        this.tasksSettings.requiredFields = this.editRequiredFields;
        await this.tasksSettings.save();
        this.isEditingRequiredFields = false;
        this.editRequiredFields = null;
        this.showSuccess('Required fields updated.');
      } finally {
        this.isEditTaskBoardLoading = false;
      }
    },
    startEditingRequiredFields () {
      this.editRequiredFields = JSON.parse(JSON.stringify(this.tasksSettings.requiredFields));
      this.isEditingRequiredFields = true;
    },
    startReorderingTaskBoards () {
      this.editTaskBoardOrder = JSON.parse(JSON.stringify(this.tasksSettings.boards));
      this.isReorderTaskBoardsDialogVisible = true;
    },
    async saveTaskBoardOrder () {
      try {
        this.isEditTaskBoardLoading = true;
        this.tasksSettings.boards = this.editTaskBoardOrder;
        await this.tasksSettings.save();
        this.isReorderTaskBoardsDialogVisible = false;
        this.showSuccess('Task boards reordered.');
      } finally {
        this.isEditTaskBoardLoading = false;
      }
    },
    async saveCustomFields () {
      try {
        this.isEditTaskBoardLoading = true;
        this.tasksSettings.customFields = this.editCustomFields
        await this.tasksSettings.save();
        this.isEditingCustomFields = false;
        this.showSuccess('Custom fields updated.');
      } finally {
        this.isEditTaskBoardLoading = false;
      }
    },
    startEditingCustomFields () {
      this.editCustomFields = JSON.parse(JSON.stringify(this.tasksSettings.customFields));
      this.isEditingCustomFields = true;
    },
    addCustomField () {
      this.editCustomFields.push(this.newCustomField.trim());
      this.newCustomField = '';
    },
    startEditingDefaultTaskBoard () {
      this.editDefaultTaskBoard = this.tasksSettings.defaultTaskBoard || null;
      this.isEditDefaultTaskBoardDialogVisible = true;
    },
    async saveDefaultTaskBoard() {
      try {
        this.isEditTaskBoardLoading = true;
        this.tasksSettings.defaultTaskBoard = this.editDefaultTaskBoard;
        await this.tasksSettings.save();
        this.isEditDefaultTaskBoardDialogVisible = false;
        this.showSuccess('Default task board updated.');
      } finally {
        this.isEditTaskBoardLoading = false;
      }
    },
    startAddingTaskUser () {
      this.editingTaskUser = null;
      this.isEditTaskUserDialogVisible = true;
    },
    startEditingUser (user) {
      this.editingTaskUser = user.clone()
      this.isEditTaskUserDialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
