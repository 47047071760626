<template>
  <v-card
    class="d-flex flex-column flex-grow-1 pa-1"
  >
    <v-card-title>Action Panel</v-card-title>
    <v-card-text>
      <v-btn
        v-if="$canAtAnySchool('log entries')"
        style="width: 100%"
        class="mb-1"
        outlined
        color="primary"
        @click="isLogEntryDialogVisible = true"
      >
        <v-icon class="mr-1">
          mdi-pencil-box-multiple
        </v-icon>Log Entry
      </v-btn>
      <v-btn
        v-if="$canAtAnySchool('log entries')"
        style="width: 100%"
        class="mb-1"
        outlined
        color="primary"
        @click="isCreateWalkDialogVisible = true"
      >
        <v-icon class="mr-1">
          mdi-walk
        </v-icon>Start SchoolDog Walk
      </v-btn>
      <v-btn
        v-if="$canAtAnySchool('propose tasks')"
        style="width: 100%"
        class="mb-1"
        outlined
        color="primary"
        @click="isCreateTaskDialogVisible = true"
      >
        <v-icon class="mr-1">
          mdi-clipboard-text
        </v-icon>Create Task
      </v-btn>
      <v-btn
        v-if="$canAtAnySchool('record drills')"
        style="width: 100%"
        class="mb-1"
        outlined
        color="primary"
        @click="$router.push('/drills/record')"
      >
        <v-icon class="mr-1">
          mdi-whistle
        </v-icon>Record Drill
      </v-btn>
    </v-card-text>

    <log-entry-dialog
      v-model="isLogEntryDialogVisible"
      floating
    />

    <create-walk-dialog v-model="isCreateWalkDialogVisible" />

    <create-task-dialog v-model="isCreateTaskDialogVisible" />
  </v-card>
</template>

<script>
import CreateWalkDialog from './../walks/CreateWalkDialog.vue'

export default {
  components: {
    CreateWalkDialog,
  },
  data () {
    return {
      isLogEntryDialogVisible: false,
      isCreateWalkDialogVisible: false,
      isCreateTaskDialogVisible: false,
    }
  },
}
</script>

<style>
</style>
