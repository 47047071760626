<template>
  <div>
    <div
      v-if="allowDistrictLine && !dashboard"
      class="text-subtitle-2 mt-2"
    >
      Chart Filters
    </div>
    <v-checkbox
      v-if="allowDistrictLine && !dashboard"
      v-model="showDistrictLine"
      hide-details="auto"
      class="mb-1"
      dense
      label="Show &quot;All Schools&quot; data in chart"
    />
    <school-select
      v-if="allowDistrictLine && !dashboard"
      v-model="schoolIds"
      class="mb-1"
      clearable
      label="Filter chart by school"
      :permission="'view data insights'"
      multiple
    />
    <v-select
      v-if="!dashboard"
      v-model="walkType"
      label="Filter chart by walk type"
      clearable
      outlined
      class="mb-2 mt-1"
      dense
      hide-details="auto"
      :menu-props="{ offsetY: true }"
      :items="walkTypeItems"
    />

    <apexchart
      ref="chart"
      style="margin-top: 16px"
      type="line"
      :options="chartOptions"
      :height="dashboard ? 300 : 400"
      :series="series"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import VueApexCharts from 'vue-apexcharts';
import moment from 'moment';
import SchoolSelect from '../common/SchoolSelect.vue';

export default {
  components: {
    apexchart: VueApexCharts,
    SchoolSelect,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
    },
    observationType: {
      type: String,
      required: true,
    },
    allowDistrictLine: {
      type: Boolean,
      default: false,
    },
    dashboard: {
      type: Boolean,
      default: false,
    },
    specificSchoolId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      schoolIds: [],
      showDistrictLine: this.allowDistrictLine,
      walkType: null,
      chartOptions: {
        chart: {
          id: 'Observations-Over-Time',
          animations: {
            animateGradually: {
              enabled: false,
            },
          },
          events: {
            dataPointSelection: this.onDataPointSelection,
          },
          toolbar: {
            autoSelected: 'pan',
            offsetY: -16,
          },
        },
        xaxis: {
          categories: [],
          tickAmount: this.dashboard ? 4 : null,
        },
        title: {
          text: this.title,
        },
        markers: {
          size: 6, // Increase marker size
          hover: {
            size: 12, // Increase marker size on hover
          },
        },
        tooltip: {
          enabled: true,
          intersect: true,
          shared: false,
        },

        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
            },
            stroke: {
              curve: 'smooth',
            },
          },
        },
      },
      series: [],
    };
  },
  computed: {
    ...mapGetters('app', [
      'getSchoolById',
    ]),
    ...mapState('app', [
      'observationSettings',
      'user',
    ]),
    observationCategories() {
      if (this.observationSettings) {
        return this.observationSettings.categories;
      }
      return [];
    },
    walkTypeItems () {
      return [
        {
          text: 'District-led',
          value: 'District',
        },
        {
          text: 'School-led',
          value: 'School',
        },
        {
          text: 'Partner-led',
          value: 'Partner',
        },
      ]
    },
    observationOptionsByCategoryId() {
      const map = {};
      this.observationCategories.forEach((category) => {
        map[category.id] = this.observationItemsByCategory[category.id]
          .map((observationItem) => ({
            text: observationItem.label,
            value: observationItem.id,
          }))
          .sort((a, b) => {
            if (a.text === 'Other' && b.text === 'Other') return 0;
            if (a.text === 'Other') return 1;
            if (b.text === 'Other') return -1;
            return a.text.localeCompare(b.text);
          });
      });
      return map;
    },
  },
  watch: {
    data: {
      immediate: true,
      handler(newData) {
        if (newData) {
          const transformedData = this.transformData(newData);
          this.chartOptions.xaxis.categories = transformedData.categories;
          this.series = transformedData.series;
        }
      },
    },
    schoolIds () {
      const transformedData = this.transformData(this.data);
      this.chartOptions.xaxis.categories = transformedData.categories;
      this.series = transformedData.series;
    },
    walkType () {
      const transformedData = this.transformData(this.data);
      this.chartOptions.xaxis.categories = transformedData.categories;
      this.series = transformedData.series;
    },
    observationType () {
      const transformedData = this.transformData(this.data);
      this.chartOptions.xaxis.categories = transformedData.categories;
      this.series = transformedData.series;
    },
    title () {
      this.$refs.chart.updateOptions({
        title: {
          text: this.title,
        },
      });
    },
    showDistrictLine () {
      const transformedData = this.transformData(this.data);
      
      this.series = transformedData.series;
      if (!this.showDistrictLine) {
        this.chartOptions.colors = []
      }
      this.chartOptions.xaxis.categories = transformedData.categories;
    },
  },
  methods: {
    onDataPointSelection (event, chartContext, config) {
      const dataPointIndex = config.dataPointIndex;
      const seriesIndex = config.seriesIndex;
      if (dataPointIndex === undefined || seriesIndex === undefined) {
        return;
      }
      const month = this.chartOptions.xaxis.categories[dataPointIndex];

      const schoolId = this.series[seriesIndex].schoolId;

      // Based on the month (in style "Apr 2024"), we need to find the first and last day in the format "YYYY-MM-DD"
      const startDate = moment(month, 'MMM YYYY').startOf('month').format('YYYY-MM-DD');
      const endDate = moment(month, 'MMM YYYY').endOf('month').format('YYYY-MM-DD');
      this.$emit('show-modal', {
        schoolId,
        walkType: this.walkType,
        startDate,
        endDate,
        month,
      });
    },
    transformData(data) {
      const colors = [
        '#00E396',
        '#FEB019',
        '#FF4560',
        '#775DD0',
        '#3F51B5',
        '#03A9F4',
        '#4CAF50',
        '#F9CE1D',
        '#FF9800',
        '#33B2DF',
        '#546E7A',
        '#D4526E',
        '#8D5B4C',
        '#F86624',
        '#D7263D',
        '#1B998B',
        '#2E294E',
        '#F46036',
        '#2E3D55',
        '#FB8B24',
        '#96F2D7',
        '#8C79DB',
        '#A6E22E',
        '#EAD637',
        '#87C38F',
        '#A2E8FF',
        '#D6A7FF',
        '#B5EAEA',
        '#F87171',
        '#4FD3C4',
        '#6E2594',
        '#DD2C00',
        '#00796B',
        '#0073E6',
        '#E91E63',
        '#7986CB',
        '#FF6E40',
        '#6D4C41',
        '#EF5350',
        '#EC407A',
        '#AB47BC',
        '#7E57C2',
        '#66BB6A',
        '#FFA726',
        '#FFEE58',
        '#26A69A',
        '#FF4081',
        '#7B1FA2',
        '#5C6BC0',
        '#8E24AA',
      ];
      const allMonths = Object.keys(data || []).sort();
      const startDate = moment(allMonths[0], 'YYYY-MM');
      const endDate = moment(allMonths[allMonths.length - 1], 'YYYY-MM');
      let allSchoolIds = Array.from(new Set(Object.values(data).flatMap(entry => Object.keys(entry.countsBySchool || {}))));
      allSchoolIds = allSchoolIds.filter(schoolId => this.schoolIds.length === 0 || this.schoolIds.includes(schoolId));
      if (this.specificSchoolId) {
        allSchoolIds = [
          this.specificSchoolId,
        ]
      }

      // Generate all months between the start and end date
      const monthsRange = [];
      const currentMonth = startDate.clone();
      while (currentMonth.isSameOrBefore(endDate, 'month')) {
        monthsRange.push(currentMonth.format('YYYY-MM'));
        currentMonth.add(1, 'month');
      }

      const formattedMonths = monthsRange.map(month => moment(month, 'YYYY-MM').format('MMM YYYY'));

      allSchoolIds.sort((a, b) => {
        return this.getSchoolById(a).name.localeCompare(this.getSchoolById(b).name);
      })
      const series = allSchoolIds.map((schoolId, index) => {
        return {
          name: this.getSchoolById(schoolId).name,
          schoolId: schoolId,
          color: colors[index % colors.length],
          data: monthsRange.map(month => {
            const entry = data[month];
            if (!entry || !entry.countsBySchool[schoolId]) {
              return 0;
            }
            let sum = 0;
            if (this.observationType === 'all') {
              if (this.walkType) {
                sum += entry.countsBySchool[schoolId][`total${this.walkType}Observations`] || 0;
              } else {
                sum += entry.countsBySchool[schoolId].totalObservations || 0;
              }
            }
            if (this.observationType === 'concern') {
              if (this.walkType) {
                sum += entry.countsBySchool[schoolId][`total${this.walkType}Concerns`] || 0;
              } else {
                sum += entry.countsBySchool[schoolId].totalConcerns || 0;
              }
            }
            if (this.observationType === 'praise') {
              if (this.walkType) {
                sum += entry.countsBySchool[schoolId][`total${this.walkType}Praises`] || 0;
              } else {
                sum += entry.countsBySchool[schoolId].totalPraises || 0;
              }
            }
            if (this.observationType === 'other') {
              if (this.walkType) {
                sum += entry.countsBySchool[schoolId][`total${this.walkType}Other`] || 0;
              } else {
                sum += entry.countsBySchool[schoolId].totalOther || 0;
              }
            }
            return sum;
          }),
        };
      })

      if (this.showDistrictLine) {
        // how to make this series always black?
        const districtSeries = {
          name: 'All Schools',
          color: '#000000',
          schoolId: null,
          data: monthsRange.map(month => {
            const entry = data[month];
            if (!entry) {
              return 0;
            }
            let sum = 0;
            if (this.observationType === 'concern') {
              if (this.walkType) {
                sum += entry[`total${this.walkType}Concerns`] || 0;
              } else {
                sum += entry.totalConcerns || 0;
              }
            }
            if (this.observationType === 'praise') {
              if (this.walkType) {
                sum += entry[`total${this.walkType}Praises`] || 0;
              } else {
                sum += entry.totalPraises || 0;
              }
            }
            if (this.observationType === 'other') {
              if (this.walkType) {
                sum += entry[`total${this.walkType}Other`] || 0;
              } else {
                sum += entry.totalOther || 0;
              }
            }
            if (this.observationType === 'all') {
              if (this.walkType) {
                sum += entry[`total${this.walkType}Observations`] || 0;
              } else {
                sum += entry.totalObservations || 0;
              }
            }
            return sum;
          }),
        };
        // put district series in front
        series.unshift(districtSeries);
      }

      return {
        categories: formattedMonths,
        series: series,
      };
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .apexcharts-series-markers .apexcharts-marker {
  cursor: pointer
}

::v-deep .apexcharts-toolbar {
  z-index: 1 !important;
}

@media (max-width: 600px) {
  .apexcharts-title-text {
    margin-top: 40px !important; /* Increase margin to avoid overlap */
  }
}
</style>