import { db } from '@/firebase-setup'
import { doc, collection, addDoc, Timestamp } from 'firebase/firestore';

import FirestoreModel from './FirestoreModel';

class Task extends FirestoreModel {
    static collectionName = 'tasks';
    static allowedFields = [
      'walkId',
      'schoolId',
      'imagePaths',
      'location',
      'description',
      'timestamp',
      'priority',
      'observationId', // optional
      'observation',
      'createdByUserId',
      'dueDate',
      'timeCompleted',
      'status',
      'notes',
      'boardId',
      'publicId',
      'isPinned',
      'customFields',
      'comments',
      'statusHistory',
      'assignmentHistory',
      'assignedUserIdsMap',
      'followingUserIdsMap',
    ]

    constructor(id, data) {
      super(id, data);
      if (!this.statusHistory) {
        this.statusHistory = {};
      }
      if (!this.assignmentHistory) {
        this.assignmentHistory = {};
      }
      if (!this.assignedUserIdsMap) {
        this.assignedUserIdsMap = {};
      }
      if (!this.followingUserIdsMap) {
        this.followingUserIdsMap = {};
      }
    }

    setAssignedUserIds(byUserId, assignedUserIds, dontAddHistory = false, dontSave = false) {
      const currentAssignedUserIds = this.getAssignedUserIds();
      this.assignedUserIdsMap = Object.fromEntries(assignedUserIds.map((userId) => [
        userId,
        true,
      ]));
      assignedUserIds.forEach((userId) => {
        this.followingUserIdsMap[userId] = true;
      });
      if (!this.assignmentHistory) {
        this.assignmentHistory = {};
      }
      if (!dontAddHistory) {
        this.assignmentHistory[(new Date()).toISOString()] = {
          assignedUserIds: this.getAssignedUserIds(),
          byUserId,
        }

        const taskSnapshot = this.toJson();
        const nowAssignedUserIds = this.getAssignedUserIds();
        const newlyAssignedUserIds = nowAssignedUserIds.filter((userId) => !currentAssignedUserIds.includes(userId));

        const assignmentHistoryCollection = collection(db, `tasks/${this.id}/assignmentHistory`);
        addDoc(assignmentHistoryCollection, {
          newlyAssignedUserIds,
          assignedUserIds: nowAssignedUserIds,
          previousAssignedUserIds: currentAssignedUserIds,
          byUserId,
          timestamp: Timestamp.now(),
          taskSnapshot,
        });
      }
      if (dontSave) {
        return this
      }
      return this.save();
    }

    addAssignedUser(byUserId, assignedUserId) {
      return this.setAssignedUserIds(byUserId, [
        ...this.getAssignedUserIds(),
        assignedUserId,
      ]);
    }

    removeAssignedUser(byUserId, assignedUserId) {
      return this.setAssignedUserIds(byUserId, this.getAssignedUserIds().filter((userId) => userId !== assignedUserId));
    }

    getAssignedUserIds() {
      return Object.keys(this.assignedUserIdsMap || {});
    }

    updateStatus(byUserId, status, comment) {
      const taskSnapshot = this.toJson();
      if (!this.statusHistory) {
        this.statusHistory = {};
      }

      const mostRecentStatusHistoryKey = Object.keys(this.statusHistory).sort().reverse()[0];
      const mostRecentStatus = mostRecentStatusHistoryKey ? this.statusHistory[mostRecentStatusHistoryKey].status : null;
      
      if (mostRecentStatus === status) {
        return
      }

      this.status = status;

      this.statusHistory[(new Date()).toISOString()] = {
        status: this.status,
        byUserId,
        comment,
      }

      const statusHistoryCollection = collection(db, `tasks/${this.id}/statusHistory`);
      addDoc(statusHistoryCollection, {
        toStatus: this.status,
        fromStatus: mostRecentStatus,
        byUserId,
        timestamp: Timestamp.now(),
        taskSnapshot,
      });

      return this.save();
    }
}

export default Task;
