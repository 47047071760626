<template>
  <v-combobox
    v-model="selectedUsers"
    v-bind="$attrs"
    :items="filteredUsers"
    item-text="fullName"
    item-value="id"
    :multiple="true"
    :menu-props="{ maxHeight: '400' }"
    :label="label"
    return-object
    :attach="!preventAttach"
    hide-details="auto"
    chips
    :filled="type === 'filled'"
    :outlined="type === 'outlined'"
    :search-input.sync="search"
    prepend-inner-icon="mdi-account-multiple"
    :append-outer-icon="isMenuOpen ? 'mdi-check' : null"
    :menu.sync="isMenuOpen"
    @change="handleChange"
    @click="isMenuOpen = !isMenuOpen"
    @blur="isMenuOpen = false"
  >
    <template #selection="{ item }">
      <div
        v-if="item.id"
        class="py-2 pr-1"
      >
        <user-avatar
          v-if="item.id"
          :user-id="item.id"
          size="36"
        />
      </div>
    </template>
    <template #item="{ item, on }">
      <v-list-item
        :disabled="selectedUsers.length >= maxSelected && !isSelected(item)"
        v-on="on"
      >
        <v-list-item-action>
          <v-checkbox :input-value="isSelected(item)" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.fullName }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-avatar>
          <user-avatar
            v-if="item.id"
            :user-id="item.id"
            size="36"
          />
        </v-list-item-avatar>
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import UserAvatar from '../common/UserAvatar.vue';

export default {
  components: {
    UserAvatar,
  },
  props: {
    maxSelected: {
      type: Number,
      default: 5,
    },
    validUserIds: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'filled',
    },
    label: {
      type: String,
      default: 'Assigned users',
    },
    preventAttach: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valueInternal: [],
      search: '',
      isMenuOpen: false,
    };
  },
  computed: {
    ...mapGetters('app', [
      'getSchoolById',
    ]),
    ...mapState('app', [
      'user',
      'usersById',
      'users',
    ]),
    selectedUsers: {
      get() {
        return this.valueInternal.map(userId => this.usersById[userId]).filter(Boolean);
      },
      set(value) {
        this.valueInternal = value.map(user => user.id);
      },
    },
    processedUsers() {

      const users = this.validUserIds.length
        ? this.users.filter(user => this.validUserIds.includes(user.id))
        : this.users;
      const grouped = users.reduce((acc, user) => {
        if (user.id === this.user.id) {
          acc.self = acc.self || [];
          acc.self.push(user);
        } else if (user.isDistrictUser() && !user.isSchoolDogStaff) {
          acc.district = acc.district || [];
          acc.district.push(user);
        } else if ((user.roleSchoolIds || []).length > 1) {
          acc.multiple = acc.multiple || [];
          acc.multiple.push(user);
        } else if ((user.roleSchoolIds || []).length === 1) {
          acc[user.roleSchoolIds[0]] = acc[user.roleSchoolIds[0]] || [];
          acc[user.roleSchoolIds[0]].push(user);
        } else if (user.isSchoolDogStaff) {
          acc.schoolDogStaff = acc.schoolDogStaff || [];
          acc.schoolDogStaff.push(user);
        }else {
          acc.other = acc.other || [];
          acc.other.push(user);
        }
        return acc;
      }, {});

      const result = [];
      Object.keys(grouped).sort((a, b) => {
        if (a === 'self') {
          return -1;
        }
        if (b === 'self') {
          return 1;
        }
        if (a === 'district') {
          return -1;
        }
        if (b === 'district') {
          return 1;
        }
        if (a === 'multiple') {
          return -1;
        }
        if (b === 'multiple') {
          return 1;
        }
        if (a === 'schoolDogStaff') {
          return 1;
        }
        if (b === 'schoolDogStaff') {
          return -1;
        }
        if (a === 'other') {
          return 1;
        }
        if (b === 'other') {
          return -1;
        }
        return this.getSchoolById(a).name.localeCompare(this.getSchoolById(b).name);
      }).forEach(schoolId => {
        if (!grouped[schoolId].length) {
          return;
        }
        const header = schoolId === 'self' ? null : schoolId === 'district' ? 'District' : schoolId === 'multiple' ? 'Multiple Schools' : schoolId === 'schoolDogStaff' ? 'SchoolDog Staff' : schoolId === 'other' ? 'Other Users' : this.getSchoolById(schoolId).name;
        if (header) {
          result.push({ header, schoolId: schoolId });
        }
        grouped[schoolId].sort((a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`)).forEach(user => {
          result.push({ ...user, itemText: `asdf${user.firstName} ${user.lastName}` });
        });
      });
      return result;
    },
    filteredUsers() {
      return this.processedUsers
        .map(user => ({
          ...user,
          fullName: `${user.firstName} ${user.lastName}${user.id === this.user.id ? ' (You)' : ''}`,
        }))
        .filter(user =>
          user.fullName && (!this.search || user.fullName.toLowerCase().includes(this.search.toLowerCase())),
        );
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.selectedUsers = value.map(userId => this.usersById[userId]).filter(Boolean);
      },
    },
  },
  methods: {
    isSelected(user) {
      return this.selectedUsers.some(u => u.id === user.id);
    },
    remove(user) {
      const index = this.selectedUsers.findIndex(u => u.id === user.id);
      if (index > -1) {
        this.selectedUsers.splice(index, 1);
      }
    },
    handleChange(e) {
      if (this.selectedUsers.length > this.maxSelected) {
        this.selectedUsers = this.selectedUsers.slice(0, this.maxSelected);
      }
      if (!this.selectedUsers.every(user => user && typeof user === 'object')) {
        this.selectedUsers = this.selectedUsers.filter(user => user && typeof user === 'object');
      }
      this.$emit('input', this.selectedUsers.map(user => user.id));
    },
    handleMenuEvent(e) {
      this.isMenuOpen = e;
      console.log(e)
    },
  },
};
</script>

<style scoped>
/* Add any additional styles if needed */
::v-deep .v-menu__content {
  z-index: 9999 !important;
}
</style>
